<div
  [ngClass]="{
    'sm:flex sm:items-start': modalData?.appearance
  }"
  class="mb-4"
>
  <div
    [ngClass]="{
      'bg-green-100': modalData?.appearance === 'success',
      'bg-red-100': modalData?.appearance === 'danger',
      'bg-yellow-100': modalData?.appearance === 'warning',
      'bg-blue-100': modalData?.appearance === 'info',
      'mx-auto flex-shrink-0 flex items-center justify-center h-14 w-14 rounded-full sm:mx-0 sm:h-12 sm:w-12':
        modalData?.appearance
    }"
  >
    @if (modalData?.appearance === 'success') {
      <shared-icon
        [name]="'check'"
        [size]="'2xl'"
        class="text-green-400"
      ></shared-icon>
    }
    @if (modalData?.appearance === 'danger') {
      <shared-icon
        [name]="'x-octagon'"
        [size]="'2xl'"
        class="text-red-400"
      ></shared-icon>
    }
    @if (modalData?.appearance === 'warning') {
      <shared-icon
        [name]="'exclamation-triangle'"
        [size]="'2xl'"
        class="text-yellow-400"
      ></shared-icon>
    }
    @if (modalData?.appearance === 'info') {
      <shared-icon
        [name]="'info'"
        [size]="'2xl'"
        class="text-blue-400"
      ></shared-icon>
    }
  </div>
  <div
    [ngClass]="{
      'mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left':
        modalData?.appearance !== undefined
    }"
  >
    <h3
      class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300"
      id="modal-title"
    >
      {{ modalData?.title }}
    </h3>
    <div class="mt-2">
      @if (safeHtml) {
        <div
          [innerHTML]="safeHtml"
          class="text-sm text-gray-500 dark:text-white"
        ></div>
      }
    </div>
  </div>
</div>

<shared-modal-footer>
  <shared-button-holder>
    @if (confirm) {
      <shared-button (click)="onCancel()" [appearance]="'light'">
        {{ modalData?.buttonCancel }}
      </shared-button>
    }
    <shared-button
      (click)="onContinue()"
      [appearance]="this.modalData?.appearance ?? 'primary'"
    >
      {{ modalData?.buttonContinue }}
    </shared-button>
  </shared-button-holder>
</shared-modal-footer>

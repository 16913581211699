/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subscription, timer } from 'rxjs';

import {
  ComponentLoader,
  ComponentLoaderFactory,
} from '@shared/util-component-loader';
import {
  AvailbleBSPositions,
  PositioningService,
} from '@shared/util-positioning';
import { OnChange, parseTriggers, Trigger, warnOnce } from '@shared/util-utils';

import { TooltipContainerComponent } from './tooltip-container.component';
import { TooltipService } from './tooltip.service';

let id = 0;

@Directive({
  selector: '[sharedTooltip], [sharedTooltipHtml]',
  exportAs: 'shared-tooltip',
})
export class TooltipDirective implements OnInit, OnDestroy {
  tooltipId = id++;

  /**
   * Content to be displayed as tooltip.
   */
  @OnChange()
  @Input()
  sharedTooltip?: string | TemplateRef<unknown>;
  /** Fired when tooltip content changes */
  @Output()
  tooltipChange: EventEmitter<string | TemplateRef<unknown>> =
    new EventEmitter();

  /**
   * Placement of a tooltip. Accepts: "top", "bottom", "left", "right"
   */
  @Input() placement: AvailbleBSPositions = 'top';
  /**
   * Specifies events that should trigger. Supports a space separated list of
   * event names.
   */
  @Input() triggers = 'hover focus';
  /**
   * A selector specifying the element the tooltip should be appended to.
   */
  @Input() container?: string;
  /**
   * Css class for tooltip container
   */
  @Input() containerClass = '';
  @Input() boundariesElement?: 'viewport' | 'scrollParent' | 'window';
  /**
   * Allows to disable tooltip
   */
  @Input() isDisabled = false;
  /**
   * Delay before showing the tooltip
   */
  @Input() delay = 0;
  /**
   * Emits an event when the tooltip is shown
   */
  @Output() onShown: EventEmitter<unknown>;
  /**
   * Emits an event when the tooltip is hidden
   */
  @Output() onHidden: EventEmitter<unknown>;
  /** @deprecated - removed, will be added to configuration */
  @Input() tooltipAnimation = true;
  /** @deprecated */
  @Input() tooltipFadeDuration = 150;
  /** @deprecated */
  @Output()
  tooltipStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected _delayTimeoutId?: number;
  protected _tooltipCancelShowFn?: () => void;
  private _tooltip: ComponentLoader<TooltipContainerComponent>;
  private _delaySubscription?: Subscription;
  private _ariaDescribedby?: string;

  constructor(
    _viewContainerRef: ViewContainerRef,
    cis: ComponentLoaderFactory,
    config: TooltipService,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private _positionService: PositioningService,
  ) {
    this._tooltip = cis
      .createLoader<TooltipContainerComponent>(
        this._elementRef,
        _viewContainerRef,
        this._renderer,
      )
      .provide({ provide: TooltipService, useValue: config });

    Object.assign(this, config);
    this.onShown = this._tooltip.onShown;
    this.onHidden = this._tooltip.onHidden;
  }

  /**
   * Returns whether or not the tooltip is currently being shown
   */
  @Input()
  get isOpen(): boolean {
    return this._tooltip.isShown;
  }

  set isOpen(value: boolean) {
    if (value) {
      this.show();
    } else {
      this.hide();
    }
  }

  /** @deprecated - please use `tooltip` instead */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('sharedTooltipHtml')
  set htmlContent(value: string | TemplateRef<unknown>) {
    warnOnce('tooltipHtml was deprecated, please use `tooltip` instead');
    this.sharedTooltip = value;
  }

  get _isOpen(): boolean {
    warnOnce('tooltipIsOpen was deprecated, please use `isOpen` instead');

    return this.isOpen;
  }

  get _enable(): boolean {
    warnOnce('tooltipEnable was deprecated, please use `isDisabled` instead');

    return this.isDisabled;
  }

  get _appendToBody(): boolean {
    warnOnce(
      'tooltipAppendToBody was deprecated, please use `container="body"` instead',
    );

    return this.container === 'body';
  }

  ngOnInit(): void {
    this._tooltip.listen({
      triggers: this.triggers,
      show: () => this.show(),
    });
    this.tooltipChange.subscribe((value) => {
      if (!value) {
        this._tooltip.hide();
      }
    });

    this.onShown.subscribe(() => {
      this.setAriaDescribedBy();
    });

    this.onHidden.subscribe(() => {
      this.setAriaDescribedBy();
    });
  }

  setAriaDescribedBy(): void {
    this._ariaDescribedby = this.isOpen ? `tooltip-${this.tooltipId}` : void 0;

    if (this._ariaDescribedby) {
      this._renderer.setAttribute(
        this._elementRef.nativeElement,
        'aria-describedby',
        this._ariaDescribedby,
      );
    } else {
      this._renderer.removeAttribute(
        this._elementRef.nativeElement,
        'aria-describedby',
      );
    }
  }

  /**
   * Toggles an element’s tooltip. This is considered a “manual” triggering of
   * the tooltip.
   */
  toggle(): void {
    if (this.isOpen) {
      return this.hide();
    }

    this.show();
  }

  /**
   * Opens an element’s tooltip. This is considered a “manual” triggering of
   * the tooltip.
   */
  show(): void {
    this._positionService.setOptions({
      modifiers: {
        flip: {
          enabled: true,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: this.boundariesElement || 'scrollParent',
        },
      },
    });

    if (
      this.isOpen ||
      this.isDisabled ||
      this._delayTimeoutId ||
      !this.sharedTooltip
    ) {
      return;
    }

    const showTooltip = (): void => {
      if (this._delayTimeoutId) {
        this._delayTimeoutId = undefined;
      }

      this._tooltip
        .attach(TooltipContainerComponent)
        .to(this.container)
        .position({ attachment: this.placement })
        .show({
          content: this.sharedTooltip,
          placement: this.placement,
          containerClass: this.containerClass,
          id: `tooltip-${this.tooltipId}`,
        });
    };
    const cancelDelayedTooltipShowing = (): void => {
      if (this._tooltipCancelShowFn) {
        this._tooltipCancelShowFn();
      }
    };

    if (this.delay) {
      if (this._delaySubscription) {
        this._delaySubscription.unsubscribe();
      }

      this._delaySubscription = timer(this.delay).subscribe(() => {
        showTooltip();
        cancelDelayedTooltipShowing();
      });

      if (this.triggers) {
        parseTriggers(this.triggers).forEach((trigger: Trigger) => {
          if (!trigger.close) {
            return;
          }
          this._tooltipCancelShowFn = this._renderer.listen(
            this._elementRef.nativeElement,
            trigger.close,
            () => {
              this._delaySubscription?.unsubscribe();
              cancelDelayedTooltipShowing();
            },
          );
        });
      }
    } else {
      showTooltip();
    }
  }

  /**
   * Closes an element’s tooltip. This is considered a “manual” triggering of
   * the tooltip.
   */
  hide(): void {
    if (this._delayTimeoutId) {
      clearTimeout(this._delayTimeoutId);
      this._delayTimeoutId = undefined;
    }

    if (!this._tooltip.isShown) {
      return;
    }

    if (this._tooltip.instance?.classMap) {
      this._tooltip.instance.classMap['in'] = false;
    }

    setTimeout(() => {
      this._tooltip.hide();
    }, this.tooltipFadeDuration);
  }

  ngOnDestroy(): void {
    this._tooltip.dispose();
    this.tooltipChange.unsubscribe();
    if (this._delaySubscription) {
      this._delaySubscription.unsubscribe();
    }
    this.onShown.unsubscribe();
    this.onHidden.unsubscribe();
  }
}

/* eslint-disable */
import { Injectable } from '@angular/core';

import { LocalStorageService } from '../../../core/services/local-storage/local-storage.service';
import { CatalogCategoryProduct } from '../../catalog-category/catalog-category-models';

@Injectable()
export class CatalogLastViewedService {
  lastViewed: Array<CatalogCategoryProduct> = [];

  constructor(private localStorageService: LocalStorageService) {
    const savedLastViewed = this.localStorageService.getLastViewed();

    this.lastViewed = savedLastViewed !== null ? savedLastViewed : [];
  }

  add(product: CatalogCategoryProduct) {
    const exists = this.lastViewed.find(function (obj) {
      return obj.url === product.url;
    });
    if (exists === undefined) {
      if (this.lastViewed.length === 5) {
        this.lastViewed.shift();
      }

      this.lastViewed.push(product);
    }

    this.localStorageService.setLastViewed(this.lastViewed);
  }
}

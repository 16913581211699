/* eslint-disable */
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Environment } from './environment.model';

export let ENVIRONMENT: Environment;

@Injectable()
export class EnvironmentService {
  private http: HttpClient;

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  init() {
    return new Promise((resolve, reject) => {
      this.http.get('environment.json').subscribe((x: any) => {
        const e = new Environment();
        ENVIRONMENT = Object.assign(e, x);
        resolve(true);
      });
    });
  }
}

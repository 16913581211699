/* eslint-disable */
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'shop-newsletter',
  templateUrl: './newsletter.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsletterComponent {
  // @ts-ignore
  newsletterMail: string;
  // @ts-ignore
  submitted: boolean;

  constructor(private router: Router) {}

  onSend(valid: any) {
    if (valid === true) {
      this.submitted = false;
      this.router.navigate(['/page/newsletter-success']);
      this.newsletterMail = '';
    } else {
      this.submitted = true;
    }
  }
}

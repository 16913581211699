<div class="relative flex items-start">
  <div class="flex items-center h-5">
    <input
      type="checkbox"
      [name]="name"
      [id]="id"
      [formControl]="formControl"
      class="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
    />
  </div>
  <div class="ml-3 text-sm">
    @if (label) {
      <label [for]="id" class="font-medium text-gray-700">{{ label }}</label>
    }
    <ng-content></ng-content>
    @if (help) {
      <p class="text-gray-500">{{ help }}</p>
    }
  </div>
</div>

<main class="mx-auto pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
  <div
    class="border-b border-gray-200 py-2.5 flex space-x-2 items-center overflow-hidden"
  >
    <shared-breadcrumb
      (navigate)="onBreadcrumbNavigate($event)"
      [dataSource]="breadcrumbDataSource"
    ></shared-breadcrumb>
  </div>

  @if (cart.products.length === 0) {
    <p class="mt-12 text-sm">Er zitten geen producten in uw winkelwagen.</p>
  }
  @if (cart.products.length > 0) {
    <form
      class="mt-6 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16"
    >
      <section aria-labelledby="cart-heading" class="lg:col-span-7">
        <ul
          class="border-b border-gray-200 divide-y divide-gray-200"
          role="list"
        >
          @for (product of cart.products; track product; let i = $index) {
            <li class="flex py-6 sm:py-10">
              <a [routerLink]="'/product/' + product.url" class="flex-shrink-0">
                <img
                  alt=""
                  class="w-24 h-24 rounded object-center object-cover sm:w-48 sm:h-48"
                  src="{{
                    product.image
                  }}"
                />
              </a>
              <div class="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                <div
                  class="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0"
                >
                  <div>
                    <div class="flex justify-between">
                      <h3 class="text-sm">
                        <a
                          [routerLink]="'/product/' + product.url"
                          class="font-medium text-gray-700 hover:text-gray-800"
                        >
                          {{ product.name }}
                        </a>
                      </h3>
                    </div>
                    <div class="mt-1 flex text-sm">
                      <p class="text-gray-500">
                        {{ product.brand.name }}
                      </p>
                    </div>
                    <p class="mt-1">
                      <!--    font-medium text-gray-900-->
                      <span
                        [ngClass]="{
                          'text-red-600': product.salePrice !== null
                        }"
                        class="font-medium text-gray-900"
                      >
                        {{
                          product.price | currency: 'EUR' : 'symbol' : '1.2-2'
                        }}
                      </span>
                      @if (product.salePrice !== null) {
                        <span
                          [ngClass]="{
                            'line-through text-gray-500 ml-1':
                              product.salePrice !== null
                          }"
                        >
                          {{
                            product.salePrice
                              | currency: 'EUR' : 'symbol' : '1.2-2'
                          }}
                        </span>
                      }
                    </p>
                  </div>
                  <div class="mt-4 sm:mt-0 sm:pr-9">
                    <select
                      (ngModelChange)="onChangeSum(i, product.stock, $event)"
                      [(ngModel)]="product.sum"
                      class="max-w-full rounded border border-gray-300 py-1.5 text-base leading-5 font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                      name="quantity-{{ i }}"
                    >
                      @for (stock of stocks(product.stock);
                        track stock;
                        let i = $index) {
                        <option [ngValue]="i + 1">
                          {{ i + 1 }}
                        </option>
                      }
                    </select>
                    <div class="absolute top-0 right-0">
                      <button
                        (click)="onRemove(i)"
                        class="-m-2 p-2 inline-flex text-gray-400 hover:text-gray-500"
                        type="button"
                      >
                        <span class="sr-only">Remove</span>
                        <shared-icon [name]="'x-lg'"></shared-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <p class="mt-4 flex text-sm text-gray-700 space-x-2">
                  <!-- Heroicon name: solid/check -->
                  <svg
                    aria-hidden="true"
                    class="flex-shrink-0 h-5 w-5 text-green-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clip-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      fill-rule="evenodd"
                    />
                  </svg>
                  <span>Direct geleverd</span>
                </p>
              </div>
            </li>
          }
        </ul>
      </section>
      <!-- Order summary -->
      <section aria-labelledby="summary-heading" class="lg:col-span-5">
        <div class="mt-16 bg-gray-50 rounded px-4 py-6 sm:p-6 lg:p-8 lg:mt-0">
          <h2 class="text-lg font-medium text-gray-900" id="summary-heading">
            Totaalprijs
          </h2>
          <dl class="mt-6 space-y-4">
            <div class="flex items-center justify-between">
              <dt class="text-sm text-gray-600">Subtotaal</dt>
              <dd class="text-sm font-medium text-gray-900">
                {{ totalSum | currency: 'EUR' : 'symbol' : '1.2-2' }}
              </dd>
            </div>
            <div
              class="border-t border-gray-200 pt-4 flex items-center justify-between"
            >
              <dt class="text-sm text-gray-600">Verzending</dt>
              <dd class="text-sm font-medium text-gray-900">
                {{ shipment | currency: 'EUR' : 'symbol' : '1.2-2' }}
              </dd>
            </div>
            <div
              class="border-t border-gray-200 pt-4 flex items-center justify-between"
            >
              <dt class="text-base font-medium text-gray-900">
                Totaalprijs (inclusief btw)
              </dt>
              <dd class="text-base font-medium text-gray-900">
                {{ totalSum + shipment | currency: 'EUR' : 'symbol' : '1.2-2' }}
              </dd>
            </div>
          </dl>
          <div class="mt-6">
            <button
              (click)="onOrder()"
              class="w-full bg-primary-500 border border-transparent rounded shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500"
              type="button"
            >
              Bestellen
            </button>
          </div>
        </div>
        <div
          class="mt-6 border border-gray-200 rounded px-4 py-6 sm:p-6 lg:p-8 text-sm"
        >
          <p>Gratis verzending vanaf 100 euro in NL.</p>
          <br />
          <p class="text-sm text-gray-500">
            * Gratis verzending vanaf 150 euro voor Duitsland, Frankrijk en
            België.
          </p>
        </div>
      </section>
    </form>
  }

  <!-- Related products -->
  <!--  <section aria-labelledby="related-heading" class="mt-24">-->
  <!--    <h2 class="text-lg font-medium text-gray-900" id="related-heading">You may also like&hellip;</h2>-->

  <!--    <div class="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">-->
  <!--      <div class="group relative">-->
  <!--        <div-->
  <!--          class="w-full min-h-80 aspect-w-1 aspect-h-1 rounded overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">-->
  <!--          <img alt="Front of Billfold Wallet in natural leather."-->
  <!--               class="w-full h-full object-center object-cover lg:w-full lg:h-full"-->
  <!--               src="https://tailwindui.com/img/ecommerce-images/shopping-cart-page-01-related-product-01.jpg">-->
  <!--        </div>-->
  <!--        <div class="mt-4 flex justify-between">-->
  <!--          <div>-->
  <!--            <h3 class="text-sm text-gray-700">-->
  <!--              <a href="#">-->
  <!--                <span aria-hidden="true" class="absolute inset-0"></span>-->
  <!--                Billfold Wallet-->
  <!--              </a>-->
  <!--            </h3>-->
  <!--            <p class="mt-1 text-sm text-gray-500">Natural</p>-->
  <!--          </div>-->
  <!--          <p class="text-sm font-medium text-gray-900">$118</p>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      &lt;!&ndash; More products... &ndash;&gt;-->
  <!--    </div>-->
  <!--  </section>-->
</main>

<!--<select (ngModelChange)="onChangeShipmentCountry($event)" [(ngModel)]="country" class="form-control"-->
<!--        id="exampleSelect1">-->
<!--  <option [value]="0">Nederland ( €4,95 )</option>-->
<!--  <option [value]="1">België ( €8,95 )</option>-->
<!--  <option [value]="2">Duitsland ( €8,95 )</option>-->
<!--  <option [value]="3">Frankrijk ( €8,95 )</option>-->
<!--</select>-->

/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppResolverService } from './app-resolver.service';
import { Angulartics2GoogleAnalytics } from 'angulartics2';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'shop-root',
  templateUrl: './app.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  app: any;
  mobileMenuVisible = false;
  showSpecificHeaderMessage?: boolean;

  constructor(
    private router: Router,
    private appResolverService: AppResolverService,
    private readonly angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
  ) {
    this.angulartics2GoogleAnalytics.startTracking();
  }

  ngOnInit() {
    // Show specific header message
    // const fromDate = new Date("2023/11/16");
    // const toDate = new Date("2023/11/21");
    // const currentDate = new Date();
    // this.showSpecificHeaderMessage = currentDate > fromDate && currentDate < toDate

    // Listen's to the Event Emitter from the AppResolverService.
    this.appResolverService.eventEmitter.subscribe((o) => {
      this.app = o;
    });

    // Scrolls to the top of the Document when Routing to a new Route.
    this.router.events.subscribe((event) => {
      window.scrollTo(0, 0);
    });
  }

  onMessenger() {
    if (this.app !== undefined) {
      window.open(this.app.facebookMessenger, 'mMe', 'width=500, height=800');
    }
  }

  onOpenMenu() {
    this.mobileMenuVisible = !this.mobileMenuVisible;
  }
}

<!--<div class="relative bg-primary-50 border border-primary-100 text-center">-->
<!--  <div class="max-w-4xl mx-auto py-4 justify-between px-4 lg:px-8">-->
<!--    <h1 class="text-lg font-semibold tracking-tight text-primary-700 md:text-5xl">WEBWINKEL (TIJDELIJK) GESLOTEN</h1>-->

<!--    <img class="w-[700px] max-w-full ml-auto mr-auto mt-4 border border-primary-100 rounded"-->
<!--         src="/assets/images/webshop-offline.png" />-->
<!--    <div class="flex items-center">-->
<!--      <p class="text-gray-500">Wegens <u>vakantie</u> is onze webwinkel tijdelijk gesloten tot en met 11 december.-->
<!--        Eventuele bestellingen-->
<!--        worden-->
<!--        pas na 11 december verwerkt. Onze excuses voor het ongemak.</p>-->
<!--      <shared-icon [name]="'exclamation'" class="text-[90px] text-red-500"></shared-icon>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<header class="relative bg-white">
  <div
    class="bg-secondary-600 h-10 text-sm font-medium text-white"
  >
    <!--    Gratis verzending vanaf 100 euro!-->
    <div class="max-w-7xl mx-auto px-4 lg:px-8">
      <ul class="grid grid-cols-1 md:grid-cols-3" role="list">
        <li class="flex flex-col">
                <span
                  class="relative flex-1 flex flex-col justify-center py-3 text-center md:text-left focus:z-10"
                >
                  <p class="text-sm tracking-tight">
                    <svg
                      class="inline-block w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Gratis verzending vanaf 100 euro
                  </p>
                </span>
        </li>

        <li class="flex flex-col hidden md:block">
                <span
                  class="relative flex-1 flex flex-col justify-center py-3 text-center focus:z-10"
                >
                  <p class="text-sm tracking-tight">
                    <svg
                      class="inline-block w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Retourneren binnen 60 dagen
                  </p>
                </span>
        </li>

        <li class="flex flex-col  hidden md:block">
                <span
                  class="relative flex-1 flex flex-col justify-center py-3 text-center md:text-right focus:z-10"
                >
                  <p class="text-sm tracking-tight">
                    <svg
                      class="w-6 h-6 inline-block"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Gratis persoonlijk advies
                  </p>
                </span>
        </li>
      </ul>
    </div>

    <!--<ng-container *ngIf="showSpecificHeaderMessage">-->
    <!--  We zijn er even tussen uit. We versturen je pakketje de 22e!-->
    <!--</ng-container>-->
    <!--<ng-container *ngIf="!showSpecificHeaderMessage">-->
    <!--  Gratis verzending vanaf 150 euro in NL!-->
    <!--</ng-container>-->
  </div>

  <div
    class="flex items-center max-w-7xl mx-auto py-4 justify-between px-4 lg:px-8"
  >
    <!-- Logo -->
    <a [routerLink]="'/'">
      <img alt="" class="h-14 w-auto" src="assets/images/logo.png" />
    </a>

    <!-- Cart -->
    <div class="ml-4 flow-root lg:ml-6">
      <shop-catalog-cart></shop-catalog-cart>
    </div>
  </div>

  <nav aria-label="Top">
    <div class="max-w-7xl mx-auto px-4 lg:px-8">
      <div class="h-10 flex items-center border-b border-gray-200">
        <!-- Mobile menu toggle, controls the 'mobileMenuOpen' state. -->
        <button
          (click)="onOpenMenu()"
          class="text-gray-400 md:hidden"
          type="button"
        >
          <span class="sr-only">Open menu</span>
          <!-- Heroicon name: outline/menu -->
          @if (!mobileMenuVisible) {
            <svg
              aria-hidden="true"
              class="h-8 w-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 6h16M4 12h16M4 18h16"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
          }
          @if (mobileMenuVisible) {
            <svg
              class="w-8 h-8"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 18 18 6M6 6l12 12"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          }
        </button>

        <!-- Flyout menus -->
        <div class="hidden w-full md:flex md:self-stretch">
          <div class="h-full flex space-x-6">
            @for (navigation of app?.navigations; track navigation) {
              <!--            <div class="flex">-->
                <!--              <div class="relative flex">-->
                <!--                &lt;!&ndash; Item active: "border-indigo-600 text-indigo-600", Item inactive: "border-transparent text-gray-700 hover:text-gray-800" &ndash;&gt;-->
                <!--                <button aria-expanded="false"-->
                <!--                        class="border-transparent text-gray-700 hover:text-gray-800 relative z-10 flex items-center transition-colors ease-out duration-200 text-sm font-medium border-b-2 -mb-px pt-px"-->
                <!--                        type="button">-->
                <!--                  Women-->
                <!--                </button>-->
                <!--              </div>-->
                <!--              &lt;!&ndash;-->
                <!--                'Women' flyout menu, show/hide based on flyout menu state.-->
                <!--                Entering: "transition ease-out duration-200"-->
                <!--                  From: "opacity-0"-->
                <!--                  To: "opacity-100"-->
                <!--                Leaving: "transition ease-in duration-150"-->
                <!--                  From: "opacity-100"-->
                <!--                  To: "opacity-0"-->
                <!--              &ndash;&gt;-->
                <!--              &lt;!&ndash;              <div class="absolute z-20 top-full inset-x-0 text-sm text-gray-500">&ndash;&gt;-->
                <!--              &lt;!&ndash;                &lt;!&ndash; Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow &ndash;&gt;&ndash;&gt;-->
                <!--              &lt;!&ndash;                <div aria-hidden="true" class="absolute inset-0 top-1/2 bg-white shadow"></div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                <div class="relative bg-white">&ndash;&gt;-->
                <!--              &lt;!&ndash;                  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">&ndash;&gt;-->
                <!--              &lt;!&ndash;                    <div class="grid grid-cols-2 gap-y-10 gap-x-8 py-16">&ndash;&gt;-->
                <!--              &lt;!&ndash;                      <div class="col-start-2 grid grid-cols-2 gap-x-8">&ndash;&gt;-->
                <!--              &lt;!&ndash;                        <div class="group relative text-base sm:text-sm">&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <div&ndash;&gt;-->
                <!--              &lt;!&ndash;                            class="aspect-w-1 aspect-h-1 rounded bg-gray-100 overflow-hidden group-hover:opacity-75">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <img alt="Models sitting back to back, wearing Basic Tee in black and bone."&ndash;&gt;-->
                <!--              &lt;!&ndash;                                 class="object-center object-cover"&ndash;&gt;-->
                <!--              &lt;!&ndash;                                 src="https://tailwindui.com/img/ecommerce-images/mega-menu-category-01.jpg">&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <a class="mt-6 block font-medium text-gray-900" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <span aria-hidden="true" class="absolute z-10 inset-0"></span>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            New Arrivals&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <p aria-hidden="true" class="mt-1">Shop now</p>&ndash;&gt;-->
                <!--              &lt;!&ndash;                        </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                        <div class="group relative text-base sm:text-sm">&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <div&ndash;&gt;-->
                <!--              &lt;!&ndash;                            class="aspect-w-1 aspect-h-1 rounded bg-gray-100 overflow-hidden group-hover:opacity-75">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <img alt="Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees."&ndash;&gt;-->
                <!--              &lt;!&ndash;                                 class="object-center object-cover"&ndash;&gt;-->
                <!--              &lt;!&ndash;                                 src="https://tailwindui.com/img/ecommerce-images/mega-menu-category-02.jpg">&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <a class="mt-6 block font-medium text-gray-900" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <span aria-hidden="true" class="absolute z-10 inset-0"></span>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            Basic Tees&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <p aria-hidden="true" class="mt-1">Shop now</p>&ndash;&gt;-->
                <!--              &lt;!&ndash;                        </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                      </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                      <div class="row-start-1 grid grid-cols-3 gap-y-10 gap-x-8 text-sm">&ndash;&gt;-->
                <!--              &lt;!&ndash;                        <div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <p class="font-medium text-gray-900" id="Clothing-heading">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            Clothing&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </p>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <ul aria-labelledby="Clothing-heading" class="mt-6 space-y-6 sm:mt-4 sm:space-y-4"&ndash;&gt;-->
                <!--              &lt;!&ndash;                              role="list">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Tops&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Dresses&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Pants&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Denim&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Sweaters&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                T-Shirts&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Jackets&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Activewear&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Browse All&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </ul>&ndash;&gt;-->
                <!--              &lt;!&ndash;                        </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                        <div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <p class="font-medium text-gray-900" id="Accessories-heading">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            Accessories&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </p>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <ul aria-labelledby="Accessories-heading" class="mt-6 space-y-6 sm:mt-4 sm:space-y-4"&ndash;&gt;-->
                <!--              &lt;!&ndash;                              role="list">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Watches&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Wallets&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Bags&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Sunglasses&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Hats&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Belts&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </ul>&ndash;&gt;-->
                <!--              &lt;!&ndash;                        </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                        <div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <p class="font-medium text-gray-900" id="Brands-heading">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            Brands&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </p>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <ul aria-labelledby="Brands-heading" class="mt-6 space-y-6 sm:mt-4 sm:space-y-4" role="list">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Full Nelson&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                My Way&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Re-Arranged&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Counterfeit&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Significant Other&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </ul>&ndash;&gt;-->
                <!--              &lt;!&ndash;                        </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                      </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                    </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                  </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;              </div>&ndash;&gt;-->
                <!--            </div>-->
                <!--            <div class="flex">-->
                <!--              <div class="relative flex">-->
                <!--                &lt;!&ndash; Item active: "border-indigo-600 text-indigo-600", Item inactive: "border-transparent text-gray-700 hover:text-gray-800" &ndash;&gt;-->
                <!--                <button aria-expanded="false"-->
                <!--                        class="border-transparent text-gray-700 hover:text-gray-800 relative z-10 flex items-center transition-colors ease-out duration-200 text-sm font-medium border-b-2 -mb-px pt-px"-->
                <!--                        type="button">-->
                <!--                  Men-->
                <!--                </button>-->
                <!--              </div>-->
                <!--              &lt;!&ndash;-->
                <!--                'Men' flyout menu, show/hide based on flyout menu state.-->
                <!--                Entering: "transition ease-out duration-200"-->
                <!--                  From: "opacity-0"-->
                <!--                  To: "opacity-100"-->
                <!--                Leaving: "transition ease-in duration-150"-->
                <!--                  From: "opacity-100"-->
                <!--                  To: "opacity-0"-->
                <!--              &ndash;&gt;-->
                <!--              &lt;!&ndash;              <div class="absolute z-20 top-full inset-x-0 text-sm text-gray-500">&ndash;&gt;-->
                <!--              &lt;!&ndash;                &lt;!&ndash; Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow &ndash;&gt;&ndash;&gt;-->
                <!--              &lt;!&ndash;                <div aria-hidden="true" class="absolute inset-0 top-1/2 bg-white shadow"></div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                <div class="relative bg-white">&ndash;&gt;-->
                <!--              &lt;!&ndash;                  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">&ndash;&gt;-->
                <!--              &lt;!&ndash;                    <div class="grid grid-cols-2 gap-y-10 gap-x-8 py-16">&ndash;&gt;-->
                <!--              &lt;!&ndash;                      <div class="col-start-2 grid grid-cols-2 gap-x-8">&ndash;&gt;-->
                <!--              &lt;!&ndash;                        <div class="group relative text-base sm:text-sm">&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <div&ndash;&gt;-->
                <!--              &lt;!&ndash;                            class="aspect-w-1 aspect-h-1 rounded bg-gray-100 overflow-hidden group-hover:opacity-75">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <img&ndash;&gt;-->
                <!--              &lt;!&ndash;                              alt="Drawstring top with elastic loop closure and textured interior padding."&ndash;&gt;-->
                <!--              &lt;!&ndash;                              class="object-center object-cover"&ndash;&gt;-->
                <!--              &lt;!&ndash;                              src="https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg">&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <a class="mt-6 block font-medium text-gray-900" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <span aria-hidden="true" class="absolute z-10 inset-0"></span>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            New Arrivals&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <p aria-hidden="true" class="mt-1">Shop now</p>&ndash;&gt;-->
                <!--              &lt;!&ndash;                        </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                        <div class="group relative text-base sm:text-sm">&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <div&ndash;&gt;-->
                <!--              &lt;!&ndash;                            class="aspect-w-1 aspect-h-1 rounded bg-gray-100 overflow-hidden group-hover:opacity-75">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <img&ndash;&gt;-->
                <!--              &lt;!&ndash;                              alt="Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt."&ndash;&gt;-->
                <!--              &lt;!&ndash;                              class="object-center object-cover"&ndash;&gt;-->
                <!--              &lt;!&ndash;                              src="https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg">&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <a class="mt-6 block font-medium text-gray-900" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <span aria-hidden="true" class="absolute z-10 inset-0"></span>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            Artwork Tees&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <p aria-hidden="true" class="mt-1">Shop now</p>&ndash;&gt;-->
                <!--              &lt;!&ndash;                        </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                      </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                      <div class="row-start-1 grid grid-cols-3 gap-y-10 gap-x-8 text-sm">&ndash;&gt;-->
                <!--              &lt;!&ndash;                        <div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <p class="font-medium text-gray-900" id="Clothing-heading">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            Clothing&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </p>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <ul aria-labelledby="Clothing-heading" class="mt-6 space-y-6 sm:mt-4 sm:space-y-4"&ndash;&gt;-->
                <!--              &lt;!&ndash;                              role="list">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Tops&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Pants&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Sweaters&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                T-Shirts&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Jackets&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Activewear&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Browse All&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </ul>&ndash;&gt;-->
                <!--              &lt;!&ndash;                        </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                        <div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <p class="font-medium text-gray-900" id="Accessories-heading">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            Accessories&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </p>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <ul aria-labelledby="Accessories-heading" class="mt-6 space-y-6 sm:mt-4 sm:space-y-4"&ndash;&gt;-->
                <!--              &lt;!&ndash;                              role="list">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Watches&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Wallets&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Bags&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Sunglasses&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Hats&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Belts&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </ul>&ndash;&gt;-->
                <!--              &lt;!&ndash;                        </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                        <div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <p class="font-medium text-gray-900" id="Brands-heading">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            Brands&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </p>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          <ul aria-labelledby="Brands-heading" class="mt-6 space-y-6 sm:mt-4 sm:space-y-4" role="list">&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Re-Arranged&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Counterfeit&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                Full Nelson&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            <li class="flex">&ndash;&gt;-->
                <!--              &lt;!&ndash;                              <a class="hover:text-gray-800" href="#">&ndash;&gt;-->
                <!--              &lt;!&ndash;                                My Way&ndash;&gt;-->
                <!--              &lt;!&ndash;                              </a>&ndash;&gt;-->
                <!--              &lt;!&ndash;                            </li>&ndash;&gt;-->
                <!--              &lt;!&ndash;                          </ul>&ndash;&gt;-->
                <!--              &lt;!&ndash;                        </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                      </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                    </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                  </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;                </div>&ndash;&gt;-->
                <!--              &lt;!&ndash;              </div>&ndash;&gt;-->
                <!--            </div>-->
              <a
                [routerLinkActive]="'border-primary-600 text-primary-600'"
                [routerLink]="navigation.url"
                class="flex items-center border-transparent lg:px-2 border-b-2 hover:border-primary-600 text-sm font-medium text-gray-700 hover:text-primary-600"
              >
                @if (navigation.url === 'category/sale') {
                  <span
                    class="text-white rounded p-1 bg-red-600 hover:bg-red-800"
                  >
                    {{ navigation.name }}
                  </span>
                }
                @if (navigation.url !== 'category/sale') {
                  <span>{{ navigation.name }}</span>
                }
              </a>
            }
          </div>
        </div>

        <div class="ml-auto flex items-center">
          <!--          <div class="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">-->
          <!--            <a class="text-sm font-medium text-gray-700 hover:text-gray-800" href="#">Sign in</a>-->
          <!--            <span aria-hidden="true" class="h-6 w-px bg-gray-200"></span>-->
          <!--            <a class="text-sm font-medium text-gray-700 hover:text-gray-800" href="#">Create account</a>-->
          <!--          </div>-->

          <!--          <div class="hidden lg:ml-8 lg:flex">-->
          <!--            <a class="text-gray-700 hover:text-gray-800 flex items-center" href="#">-->
          <!--              <img alt="" class="w-5 h-auto block flex-shrink-0" src="https://tailwindui.com/img/flags/flag-canada.svg">-->
          <!--              <span class="ml-3 block text-sm font-medium">-->
          <!--                    CAD-->
          <!--                  </span>-->
          <!--              <span class="sr-only">, change currency</span>-->
          <!--            </a>-->
          <!--          </div>-->

          <!-- Search -->
          <!--          <div class="flex lg:ml-6">-->
          <!--            <a class="p-2 text-gray-400 hover:text-gray-500" href="#">-->
          <!--              <span class="sr-only">Search</span>-->
          <!--              &lt;!&ndash; Heroicon name: outline/search &ndash;&gt;-->
          <!--              <svg aria-hidden="true" class="w-6 h-6" fill="none" stroke="currentColor"-->
          <!--                   viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">-->
          <!--                <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" stroke-linecap="round" stroke-linejoin="round"-->
          <!--                      stroke-width="2" />-->
          <!--              </svg>-->
          <!--            </a>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
    @if (mobileMenuVisible) {
      <div
        class="z-50 items-center absolute top-[128px] bg-white w-full flex md:hidden"
      >
        <ul class="w-full">
          @for (navigation of app?.navigations; track navigation) {
            <li class="border-b border-gray-200">
              <a
                (click)="mobileMenuVisible = false"
                [routerLinkActive]="'border-primary-600 text-primary-600'"
                [routerLink]="navigation.url"
                class="flex items-center py-4 px-4 border-transparent hover:border-primary-600 text-sm font-medium text-gray-700 hover:text-primary-600"
              >{{ navigation.name }}</a
              >
            </li>
          }
        </ul>
      </div>
    }
  </nav>
</header>

<!-- Page -->
<router-outlet></router-outlet>

<footer class="bg-gray-50 border-t border-gray-100">
  <div class="max-w-7xl mx-auto px-4 lg:px-8">
    <div class="pt-20 pb-6">
      <div
        class="grid grid-cols-1 md:grid-cols-12 md:grid-flow-col md:gap-x-8 md:gap-y-16 md:auto-rows-min"
      >
        <div
          class="mt-10 col-span-6 grid grid-cols-2 gap-8 sm:grid-cols-3 md:mt-0 md:row-start-1 md:col-span-8 lg:col-span-6"
        >
          <div
            class="grid grid-cols-1 gap-y-12 sm:col-span-2 sm:grid-cols-2 sm:gap-x-8"
          >
            <div>
              <h3 class="text-sm font-medium text-gray-900">Producten</h3>
              <ul class="mt-6 space-y-6" role="list">
                <li class="text-sm">
                  <a
                    [routerLink]="'/category/new'"
                    class="text-gray-500 hover:text-gray-600"
                  >
                    Nieuw
                  </a>
                </li>

                <li class="text-sm">
                  <a
                    [routerLink]="'/category/sale'"
                    class="text-gray-500 hover:text-gray-600"
                  >
                    Aanbiedingen
                  </a>
                </li>

                <li class="text-sm">
                  <a
                    [routerLink]="'/category/gezichtsverzorging'"
                    class="text-gray-500 hover:text-gray-600"
                  >
                    Gezichtsverzorging
                  </a>
                </li>

                <li class="text-sm">
                  <a
                    [routerLink]="'/category/lichaamsverzorging'"
                    class="text-gray-500 hover:text-gray-600"
                  >
                    Lichaamsverzorging
                  </a>
                </li>

                <li class="text-sm">
                  <a
                    [routerLink]="'/category/gezondheid'"
                    class="text-gray-500 hover:text-gray-600"
                  >
                    Gezondheid
                  </a>
                </li>

                <li class="text-sm">
                  <a
                    [routerLink]="'/category/parfum'"
                    class="text-gray-500 hover:text-gray-600"
                  >
                    Parfum
                  </a>
                </li>

                <li class="text-sm">
                  <a
                    [routerLink]="'/category/beauty'"
                    class="text-gray-500 hover:text-gray-600"
                  >
                    Beauty
                  </a>
                </li>

                <li class="text-sm">
                  <a
                    [routerLink]="'/brands'"
                    class="text-gray-500 hover:text-gray-600"
                  >
                    Merken
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 class="text-sm font-medium text-gray-900">Bedrijf</h3>
              <ul class="mt-6 space-y-6" role="list">
                <li class="text-sm">
                  <a
                    [routerLink]="'/page/disclaimer'"
                    class="text-gray-500 hover:text-gray-600"
                  >
                    Disclaimer
                  </a>
                </li>

                <li class="text-sm">
                  <a
                    [routerLink]="'/page/privacy-verklaring'"
                    class="text-gray-500 hover:text-gray-600"
                  >
                    Privacy Verklaring
                  </a>
                </li>

                <li class="text-sm">
                  <a
                    [routerLink]="'/page/algemene-voorwaarden'"
                    class="text-gray-500 hover:text-gray-600"
                  >
                    Algemene voorwaarden
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h3 class="text-sm font-medium text-gray-900">Klantenservice</h3>
            <ul class="mt-6 space-y-6" role="list">
              <li class="text-sm">
                <a
                  [routerLink]="'/page/betaalmethode'"
                  class="text-gray-500 hover:text-gray-600"
                >
                  Betaalmethodes
                </a>
              </li>

              <li class="text-sm">
                <a
                  [routerLink]="'/page/levertijd-en-verzendkosten'"
                  class="text-gray-500 hover:text-gray-600"
                >
                  Levertijd &amp; verzendkosten
                </a>
              </li>

              <li class="text-sm">
                <a
                  [routerLink]="'/page/retourneren'"
                  class="text-gray-500 hover:text-gray-600"
                >
                  Retourneren
                </a>
              </li>

              <li class="text-sm">
                <a
                  [routerLink]="'/page/garantie-en-klachten'"
                  class="text-gray-500 hover:text-gray-600"
                >
                  Garantie &amp; Klachten
                </a>
              </li>

              <li class="text-sm">
                <a
                  [routerLink]="'/faq'"
                  class="text-gray-500 hover:text-gray-600"
                >
                  Veel gestelde vragen
                </a>
              </li>

              <li class="text-sm">
                <a
                  [routerLink]="'/page/contact'"
                  class="text-gray-500 hover:text-gray-600"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>

        <!-- Newsletter section -->
        <div
          class="mt-12 md:mt-0 md:row-start-2 md:col-start-3 md:col-span-8 lg:row-start-1 lg:col-start-9 lg:col-span-4"
        >
          <h3 class="text-sm font-medium text-gray-900">Schrijf je in</h3>
          <p class="mt-6 text-sm text-gray-500">
            Ontvang als eerste de nieuwste producten, trends en waanzinnige
            acties .
          </p>

          <shop-newsletter></shop-newsletter>

          <p class="mt-6 flex items-center">
            <a
              class="mr-1 float-left"
              href="https://www.facebook.com/thaibeautyshop.nl"
              target="_blank"
            >
              <svg class="w-10 h-10 relative top-[-3px]" viewBox="0 0 24 24">
                <path
                  d="M 12 4 C 6.5 4 2 8.5 2 14 c 0 5 3.7 9.2 8.5 9.9 v -7.2 H 8 V 14 h 2.5 v -1.7 c 0 -2.9 1.4 -4.2 3.8 -4.2 c 1.2 0 1.8 0.1 2.1 0.1 v 2.3 h -1.6 c -1 0 -1.4 1 -1.4 2.1 V 14 h 3 L 16 16.6 h -2.6 v 7.2 c 4.9 -0.7 8.6 -4.8 8.6 -9.9 C 22 8.5 17.5 4 12 4 Z"
                ></path>
              </svg>
            </a>
            <a
              class="mr-1 float-left"
              href="https://www.instagram.com/thaibeautyshop.nl"
              target="_blank"
            >
              <svg class="w-10 h-10" viewBox="0 0 24 24">
                <path
                  d="M 15.7 2.9 H 8.3 c -3.1 0 -5.7 2.6 -5.7 5.7 V 16 c 0 3.1 2.6 5.7 5.7 5.7 h 7.4 c 3.1 0 5.7 -2.6 5.7 -5.7 V 8.6 C 21.4 5.4 18.8 2.9 15.7 2.9 Z M 19.8 8.6 V 16 c 0 2.3 -1.9 4.1 -4.1 4.1 H 8.3 c -2.3 0 -4.1 -1.9 -4.1 -4.1 V 8.6 c 0 -2.3 1.9 -4.1 4.1 -4.1 h 7.4 C 18 4.4 19.8 6.3 19.8 8.6 Z M 12 7.4 c -2.7 0 -4.9 2.2 -4.9 4.9 s 2.2 4.9 4.9 4.9 s 4.9 -2.2 4.9 -4.9 S 14.7 7.4 12 7.4 Z M 12 15.6 c -1.8 0 -3.3 -1.5 -3.3 -3.3 S 10.2 9 12 9 s 3.3 1.5 3.3 3.3 S 13.8 15.6 12 15.6 Z M 16.9 6.1 c -0.7 0 -1.2 0.5 -1.2 1.2 c 0 0.7 0.5 1.2 1.2 1.2 c 0.7 0 1.2 -0.5 1.2 -1.2 C 18.1 6.6 17.6 6.1 16.9 6.1 Z M 16.9 7.2"
                ></path>
              </svg>
            </a>
            <a
              class="mr-1 float-left"
              href="https://www.tiktok.com/@thaibeautyshop.nl"
              target="_blank"
            >
              <svg class="w-10 h-10" viewBox="0 0 24 24">
                <path
                  d="M 20.2 10.4 V 7.2 c 0 0 -1.7 0 -3.1 -1.3 c -1.3 -1.1 -1.4 -3.2 -1.4 -3.2 h -3.2 c 0 0 0 11 0 12.7 S 11 18 9.9 18 c -0.8 0 -2.7 -0.7 -2.7 -2.6 c 0 -2.2 2.2 -2.6 2.7 -2.6 s 0.8 0.1 0.8 0.1 V 9.5 c 0 0 -0.6 -0.1 -0.9 -0.1 c -3.2 -0.1 -5.9 2.8 -5.9 6 c 0 2.7 2.1 6 6 6 c 4.2 0 6 -3.5 6 -5.9 c 0 -1.9 0 -6.3 0 -6.3 s 1.1 0.6 2 0.9 C 19 10.4 20.2 10.4 20.2 10.4 Z"
                ></path>
              </svg>
            </a>
          </p>

          <h3 class="mt-10 text-sm font-medium text-gray-900">Beveiligd betalen</h3>
          <p class="mt-6 text-sm text-gray-500">
            Onze website is volledig beveiligd en uw betaling is 100% veilig.</p>
          <img [src]="'/assets/images/beveiligd-betalen.png'" alt=""
               class="mt-6 w-24" />

        </div>
      </div>
    </div>

    <div class="py-6 text-center">
      <div class="flex space-x-2 justify-center mb-6">
        <img
          [src]="'/assets/images/payment-methods/ideal.svg'"
          alt=""
          class="h-6 w-6 rounded-sm"
        />
        <img
          [src]="'/assets/images/payment-methods/mistercash.svg'"
          alt=""
          class="h-6 w-6 rounded-sm"
        />
        <img
          [src]="'/assets/images/payment-methods/sofort.svg'"
          alt=""
          class="h-6 w-6 rounded-sm"
        />
        <img
          [src]="'/assets/images/payment-methods/kbc.svg'"
          alt=""
          class="h-6 w-6 rounded-sm"
        />
        <img
          [src]="'/assets/images/payment-methods/belfius.svg'"
          alt=""
          class="h-6 w-6 rounded-sm"
        />
        <img
          [src]="'/assets/images/payment-methods/eps.svg'"
          alt=""
          class="h-6 w-6 rounded-sm"
        />
        <img
          [src]="'/assets/images/payment-methods/sepa.svg'"
          alt=""
          class="h-6 w-6 rounded-sm"
        />
        <img
          [src]="'/assets/images/payment-methods/paypal.svg'"
          alt=""
          class="h-6 w-6 rounded-sm"
        />
      </div>
      <p class="text-sm text-gray-500">
        © 2024 {{ app?.meta.title }}, Alle rechten voorbehouden.
      </p>
    </div>
  </div>
  <!--  <div class="container">-->
  <!--    <div class="row">-->
  <!--      <div class="col-md-9">-->
  <!--        <shop-newsletter></shop-newsletter>-->
  <!--      </div>-->
  <!--      <div class="col-sm-3">-->
  <!--        <ul class="text-right list-inline mb-0">-->
  <!--          <li class="list-inline-item">-->
  <!--            <a href="https://www.facebook.com/thaibeautyshop.nl/" target="_blank">-->
  <!--              <i aria-hidden="true" class="fab fa-facebook-square"></i>-->
  <!--            </a>-->
  <!--          </li>-->
  <!--          &lt;!&ndash; <li class="list-inline-item">-->
  <!--            <a href="https://twitter.com/search?f=tweets&q=thaibeautyshop.nl" target="_blank">-->
  <!--              <i class="fab fa-twitter-square" aria-hidden="true"></i>-->
  <!--            </a>-->
  <!--          </li> &ndash;&gt;-->
  <!--        </ul>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</footer>

<!-- <div class="container pb-1"> -->

<!--<shop-footer [dataSource]="app?.footerContents"></shop-footer>-->
<!-- </div> -->
<!--<div class="mb-3">-->
<!--  <shop-copyright [dataSource]="app?.footerLinks" [title]="app?.meta.title"></shop-copyright>-->
<!--</div>-->

@if (app?.facebookMessenger !== '') {
  <div
    (click)="onMessenger()"
    style="
      cursor: pointer;
      z-index: 2147483647;
      border-top-left-radius: 0.15rem;
      border-top-right-radius: 0.15rem;
      position: fixed;
      line-height: 30px;
      background: #000;
      color: #fff;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 3px;
      right: 9%;
      bottom: 0;
      height: 30px;
      padding-left: 15px;
      padding-right: 15px;
      font-weight: 700;
    "
  >
    <i class="fab fa-facebook-square"></i> Chat met ons!
  </div>
}

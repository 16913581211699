// noinspection JSUnusedGlobalSymbols

import { Subject } from 'rxjs';

import { BaseComponent } from '@shared/util-base-component';

import { ModalRefService } from '../../services/modal-ref.service';

export abstract class BaseModal<T> extends BaseComponent {
  closed$: Subject<unknown> = new Subject();

  modalData?: T;

  protected constructor(protected readonly bsModalRef: ModalRefService) {
    super();
  }

  onClose(val: unknown): void {
    this.closed$.next(val);
    this.bsModalRef.hide();
  }

  onHide(): void {
    this.closed$.next(null);
    this.bsModalRef.hide();
  }
}

/* eslint-disable */
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Observer } from 'rxjs';

import { MetadataService } from './core/services/metadata/metadata.service';

@Injectable()
export class AppResolverService {
  eventEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private metadataService: MetadataService,
  ) {}

  /**
   * Resolves all the Data needed for Application Startup.
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {(Observable<any> | Promise<any> | any)}
   *
   * @memberOf AppResolverService
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return Observable.create((observer: Observer<boolean>) => {
      // get and return true after setting
      this.http.get<any>(`app`).subscribe((r: any) => {
        // Emits WebApp Data.
        this.eventEmitter.emit(r);

        // TODO: Get from API.
        const applicationUrl =
          window.location.protocol + '//' + window.location.host;

        this.metadataService.setGlobalMetadata({
          pageTitleSeparator: ' · ',
          applicationName: 'Thaibeautyshop.nl',
          applicationUrl: applicationUrl,
          defaults: {
            description: 'Make-up and skin care store.',
            'og:image': `${applicationUrl}/assets/images/logo.png`,
            'og:type': 'website',
            'og:locale': 'nl_NL',
            'og:locale:alternate': 'en_US,th_TH',
          },
        });

        observer.next(true);
        observer.complete();
      });
    });
  }
}

/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LocalStorageService } from '../../core/services/local-storage/local-storage.service';

import {
  Checkout,
  CheckoutCart,
  CheckoutInformation,
  CheckoutPayment,
} from './../shared/models/checkout.model';
import { CatalogCheckoutService } from './../shared/services/catalog-checkout.service';
import { CatalogCheckoutInformationService } from './catalog-checkout-information.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'shop-catalog-checkout-information',
  templateUrl: './catalog-checkout-information.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogCheckoutInformationComponent implements OnInit {
  // @ts-ignore
  cart: CheckoutCart;
  // @ts-ignore
  paymentMethods: Array<CheckoutPayment>;
  // @ts-ignore
  data: CheckoutInformation;
  // @ts-ignore
  totalSum: number;
  // @ts-ignore
  totalPaymentFee: number;
  // @ts-ignore
  shipment: number;
  // @ts-ignore
  submitted: boolean;
  // @ts-ignore
  form: FormGroup;
  isDisabled = false;
  countryDataSource: Array<{ value: number; label: string }> = [
    {
      value: 0,
      label: 'Nederland',
    },
    {
      value: 1,
      label: 'België',
    },
    {
      value: 2,
      label: 'Duitsland',
    },
    {
      value: 3,
      label: 'Frankrijk',
    },
  ];
  // <option [value]="0">Nederland ( €4,95 )</option>
  //   <option [value]="1">België ( €8,95 )</option>
  //   <option [value]="2">Duitsland ( €8,95 )</option>
  //   <option [value]="3">Frankrijk ( €8,95 )</option>
  // @ts-ignore
  private country: number = 0;

  constructor(
    private catalogCheckoutService: CatalogCheckoutService,
    private catalogcheckoutInformationService: CatalogCheckoutInformationService,
    private localStorageService: LocalStorageService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.cart = this.catalogCheckoutService.checkout.cart;

    this.getCheckoutSummary();

    this.paymentMethods = [
      {
        name: 'iDEAL',
        image: '/assets/images/payment-methods/ideal.svg',
        description: 'Betaal gratis snel en eenvoudig via iDEAL.',
        value: 'ideal',
      },
      {
        name: 'Bancontact MisterCash',
        image: '/assets/images/payment-methods/mistercash.svg',
        description:
          'Betaal gratis snel en eenvoudig via Bancontact MisterCash.',
        value: 'mistercash',
      },
      {
        name: 'SOFORT banking',
        image: '/assets/images/payment-methods/sofort.svg',
        value: 'sofort',
        surchargePercentage: 0.9,
      },
      {
        name: 'KBC/CBC',
        image: '/assets/images/payment-methods/kbc.svg',
        value: 'kbc',
        surchargePercentage: 0.9,
      },
      {
        name: 'Belfius',
        image: '/assets/images/payment-methods/belfius.svg',
        value: 'belfius',
        surchargePercentage: 0.9,
      },
      {
        name: 'EPS',
        image: '/assets/images/payment-methods/eps.svg',
        value: 'eps',
        surchargePercentage: 1.5,
      },
      {
        name: 'Overboeking',
        image: '/assets/images/payment-methods/sepa.svg',
        value: 'banktransfer',
        description: 'Handmatige overboeking.',
      },
      {
        name: 'Paypal',
        image: '/assets/images/payment-methods/paypal.svg',
        value: 'paypal',
        surchargePercentage: 3.9,
      },
    ];

    this.form = this.fb.group({
      businessOrder: [false, Validators.required],
      companyName: '',
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      postalCode: ['', Validators.required],
      streetNr: ['', Validators.required],
      streetNrAddition: '',
      street: ['', Validators.required],
      place: ['', Validators.required],
      country: this.country,
      alternativeShipmentAddress: [false, Validators.required],
      attentionOf: '',
      alternativeAddressPostalCode: '',
      alternativeAddressStreetNr: '',
      alternativeAddressStreetNrAddition: '',
      alternativeAddressStreet: '',
      alternativeAddressPlace: '',
      alternativeAddressCountry: '',
      mail: ['', Validators.required],
      phone: '',
      newsletter: false,
      paymentMethod: 'ideal',
    });

    this.form.patchValue(
      this.localStorageService.getCheckout().information as any,
    );

    this.form.get('paymentMethod')?.valueChanges.subscribe((x) => {
      this.getCheckoutSummary();
    });
  }

  /**
   * On Pay Click.
   * @memberOf CatalogCheckoutInformationComponent
   */
  onPay() {
    this.submitted = true;

    if (this.form.valid) {
      this.isDisabled = true;

      const checkout: Checkout = {
        cart: this.catalogCheckoutService.checkout.cart,
        information: this.form.value,
      };
      this.localStorageService.setCheckout(checkout);
      this.catalogcheckoutInformationService.pay(checkout).subscribe(
        (res) => {
          window.location.href = res.url;
        },
        (err) => {
          alert(
            'Er is iets niet goed gegaan, controleer je ingevulde gegevens of deze kloppen.',
          );
          this.isDisabled = false;
        },
      );
    }
  }

  onChangeCountry(data: number) {
    this.country = data;
    this.getCheckoutSummary();
  }

  /**
   * Get Checkout Summary.
   *
   * @private
   *
   * @memberOf CatalogCartComponent
   */
  getCheckoutSummary() {
    this.totalSum = 0;
    this.totalPaymentFee = 0;

    this.cart.products.forEach((value) => {
      this.totalSum += value.price * value.sum;
    });

    if (this.totalSum > 100 && +this.country === 0) {
      this.shipment = 0;
    } else {
      this.changeCountry(this.country);
    }

    this.changeCountry(this.country);

    // this.form.get('paymentMethod')
  }

  /**
   * Set the Payment Method value inside the 'Checkout Information' Model.
   *
   * @param {string} value
   *
   * @memberOf CatalogCheckoutInformationComponent
   */
  onSetPaymentMethod(value: string) {
    this.data.paymentMethod = value;
  }

  private changeCountry(country: number) {
    if (+country === 0) {
      if (this.totalSum > 100) {
        this.shipment = 0;
      } else {
        this.shipment = 6.95;
      }
    } else {
      if (this.totalSum > 150) {
        this.shipment = 0;
      } else {
        this.shipment = 9.95;
      }
    }

    const paymentMethod = this.form?.get('paymentMethod')?.value;
    switch (paymentMethod) {
      case 'ideal':
      case 'mistercash':
        this.totalPaymentFee = 0;
        break;
      case 'sofort':
      case 'kbc':
      case 'belfius':
        this.totalPaymentFee = ((this.totalSum + this.shipment) / 100) * 0.9;
        break;
      case 'eps':
      case 'giropay':
        this.totalPaymentFee = ((this.totalSum + this.shipment) / 100) * 1.5;
        break;
      case 'paypal':
        this.totalPaymentFee = ((this.totalSum + this.shipment) / 100) * 3.9;
        break;
    }
  }
}

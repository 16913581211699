import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Appearance } from '@shared/util-appearance';

@Component({
  selector: 'shared-alert',
  templateUrl: './alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  @Input() appearance: Appearance = 'light';
  @Input() icon = false;
  @Input() content?: string;
  @Input() closable = false;
  @Input() autoCloses = false;
  @Output() closed: EventEmitter<Event> = new EventEmitter();

  onClose(event: Event): void {
    this.closed.emit(event);
  }
}

<form
  action="https://shinju.us5.list-manage.com/subscribe/post"
  class="mt-2 flex sm:max-w-md"
  method="post"
  name="mc-embedded-subscribe-form"
  ngNoForm
  novalidate
  target="_blank"
>
  <input name="u" type="hidden" value="61c27bb50f8c69591e6279008" />
  <input name="id" type="hidden" value="c3163149db" />
  <input
    class="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:border-primary-500 focus:ring-1 focus:ring-primary-500"
    id="MERGE0"
    name="MERGE0"
    placeholder="Je e-mailadres..."
    type="email"
    value=""
  />
  <div class="ml-2 flex-shrink-0">
    <button
      class="button cursor-pointer inline-flex items-center font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-gray-900 hover:bg-primary-600 text-white border border-transparent px-4 py-2 text-base shadow-sm bg-primary-500 rounded"
      id="mc-embedded-subscribe"
      name="subscribe"
      type="submit"
    >
      <svg
        class="w-6 h-6"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
  <div aria-hidden="true" style="position: absolute; left: -5000px">
    <input
      name="b_61c27bb50f8c69591e6279008_c3163149db"
      tabindex="-1"
      type="text"
      value=""
    />
  </div>
</form>

/* eslint-disable */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppResolverService } from './app-resolver.service';
import { CatalogRoutes } from './catalog/catalog.routes';

// @ts-ignore
const routes: Routes = [
  {
    path: '',
    resolve: {
      metaData: AppResolverService,
    },
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        // @ts-ignore
        loadChildren: () =>
          import('./home/home.module').then((x) => x.HomeModule),
      },
      ...CatalogRoutes,
      {
        path: 'page',
        // @ts-ignore
        loadChildren: () =>
          import('./page/page.module').then((x) => x.PageModule),
      },
      {
        path: 'contact',
        // @ts-ignore
        loadChildren: () =>
          import('./contact/contact.module').then((x) => x.ContactModule),
      },
      {
        path: 'account',
        // @ts-ignore
        loadChildren: () =>
          import('./account/account.module').then((x) => x.AccountModule),
      },
      {
        path: 'faq',
        // @ts-ignore
        loadChildren: () => import('./faq/faq.module').then((x) => x.FaqModule),
      },
      {
        path: 'brands',
        // @ts-ignore
        loadChildren: () =>
          import('./catalog-brands/catalog-brands.module').then(
            (x) => x.CatalogBrandsModule,
          ),
      },
      {
        path: 'order-thanks',
        // @ts-ignore
        loadChildren: () =>
          import('./order-thanks/order-thanks.module').then(
            (x) => x.OrderThanksModule,
          ),
      },
      { path: '**', redirectTo: 'home' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AppResolverService],
})
export class AppRoutingModule {}

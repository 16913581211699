import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconComponent } from '@shared/ui-icon';

import { AlertComponent } from './alert.component';

@NgModule({
  imports: [CommonModule, IconComponent],
  declarations: [AlertComponent],
  exports: [AlertComponent],
})
export class AlertModule {}

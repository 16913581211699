/* eslint-disable */
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  LOCALE_ID,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';

import { EnvironmentService } from './services/environment/environment.service';
import { InterceptorService } from './services/interceptor/interceptor.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { MetadataService } from './services/metadata/metadata.service';
import { PageService } from './services/page/page.service';
import { registerLocaleData } from '@angular/common';

import localeNl from '@angular/common/locales/nl';

registerLocaleData(localeNl, 'nl');

/**
 * Exported function so that it works with AOT.
 * @param {EnvironmentService} environmentService
 * @returns {Function}
 * @constructor
 */
export function InitEnvironmentService(
  environmentService: EnvironmentService,
): Function {
  return () => environmentService.init();
}

@NgModule({
  imports: [HttpClientModule],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: InterceptorService,
          multi: true,
        },
        EnvironmentService,
        {
          provide: APP_INITIALIZER,
          useFactory: InitEnvironmentService,
          deps: [EnvironmentService],
          multi: true,
        },
        { provide: LOCALE_ID, useValue: 'nl-NL' },
        LocalStorageService,
        MetadataService,
        PageService,
      ],
    };
  }
}

<main
  class="lg:min-h-full lg:overflow-hidden lg:flex lg:flex-row-reverse mx-auto lg:max-w-7xl px-4 sm:px-6 lg:px-8"
>
  <div class="px-4 py-6 sm:px-6 lg:hidden">
    <div class="max-w-lg mx-auto flex">
      <h1 class="text-lg font-semibold tracking-tight text-gray-900">
        Je gegevens
      </h1>
    </div>
  </div>

  <!-- Order summary -->
  <section
    aria-labelledby="summary-heading"
    class="bg-gray-50 w-full max-w-lg flex-col flex mx-auto"
  >
    <ul
      class="flex-auto overflow-y-auto divide-y divide-gray-200 px-6"
      role="list"
    >
      <!--      <li  class="list-group-item">-->
      <!--            <span-->
      <!--              class="float-right productName">{{product.price * product.sum | currency:'EUR':'symbol':'1.2-2'}}</span> {{product.sum}}-->
      <!--        X {{product.name}}-->
      <!--      </li>-->
      @for (product of cart.products; track product) {
        <li class="flex py-6 space-x-6">
          <img
            [src]="
              '' +
              product.image
            "
            alt="Moss green canvas compact backpack with double top zipper, zipper front pouch, and matching carry handle and backpack straps."
            class="flex-none w-40 h-40 object-center object-cover bg-gray-200 rounded"
          />
          <div class="flex flex-col justify-between space-y-4">
            <div class="text-sm font-medium space-y-1">
              <h3 class="text-gray-900">{{ product.name }}</h3>
              <p>
                <span
                  [ngClass]="{ 'text-red-600': product.salePrice !== null }"
                  class="font-medium text-gray-900"
                >
                  {{ product.price | currency: 'EUR' : 'symbol' : '1.2-2' }}
                </span>
                @if (product.salePrice !== null) {
                  <span
                    [ngClass]="{
                      'line-through text-gray-500 ml-1':
                        product.salePrice !== null
                    }"
                  >
                    {{
                      product.salePrice | currency: 'EUR' : 'symbol' : '1.2-2'
                    }}
                  </span>
                }
              </p>
              <p class="text-gray-500">{{ product.brand.name }}</p>
              <p class="text-gray-500">Aantal: {{ product.sum }}</p>
            </div>
          </div>
        </li>
      }

      <!-- More products... -->
    </ul>

    <div
      class="sticky bottom-0 flex-none bg-gray-50 border-t border-gray-200 p-6"
    >
      <dl class="text-sm font-medium text-gray-500 mt-10 space-y-6">
        <div class="flex justify-between">
          <dt>Subtotaal</dt>
          <dd class="text-gray-900">
            {{ totalSum | currency: 'EUR' : 'symbol' : '1.2-2' }}
          </dd>
        </div>
        <div class="flex justify-between">
          <dt>Verzending</dt>
          <dd class="text-gray-900">
            {{ shipment | currency: 'EUR' : 'symbol' : '1.2-2' }}
          </dd>
        </div>
        @if (totalPaymentFee > 0) {
          <div class="flex justify-between">
            <dt>Betaaltoeslag</dt>
            <dd class="text-gray-900">
              {{ totalPaymentFee | currency: 'EUR' : 'symbol' : '1.2-2' }}
            </dd>
          </div>
        }
        <div
          class="flex items-center justify-between border-t border-gray-200 text-gray-900 pt-6"
        >
          <dt class="text-base">Totaal</dt>
          <dd class="text-base">
            {{
              totalSum + shipment + totalPaymentFee
                | currency: 'EUR' : 'symbol' : '1.2-2'
            }}
          </dd>
        </div>
      </dl>
    </div>
  </section>

  <!-- Checkout form -->
  <section
    aria-labelledby="payment-heading"
    class="flex-auto overflow-y-auto px-4 pt-12 pb-16 sm:px-6 sm:pt-16 lg:px-8 lg:pt-0 lg:pb-24"
  >
    <div class="max-w-lg mx-auto">
      <div class="hidden pt-6 pb-6 lg:flex">
        <h1 class="text-lg font-semibold tracking-tight text-gray-900">
          Je gegevens
        </h1>
      </div>

      <form (ngSubmit)="onPay()" [formGroup]="form" novalidate>
        <div class="grid grid-cols-12 gap-y-6 gap-x-4">
          <div class="col-span-full">
            <label
              class="block text-sm font-medium text-gray-700"
              for="email-address"
            >E-mailadres</label
            >
            <div class="mt-1">
              <input
                autocomplete="email"
                class="block w-full border-gray-300 rounded shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                formControlName="mail"
                id="email-address"
                name="email-address"
                type="email"
              />
              @if ((form.get('mail')?.dirty || submitted) &&
              form.get('mail')?.hasError('required')) {
                <div class="mt-2 text-sm text-red-600">Verplicht</div>
              }
            </div>
          </div>

          <div class="col-span-full sm:col-span-6">
            <label
              class="block text-sm font-medium text-gray-700"
              for="firstname"
            >Voornaam</label
            >
            <div class="mt-1">
              <input
                autocomplete="address-level2"
                class="block w-full border-gray-300 rounded shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                formControlName="firstName"
                id="firstname"
                name="firstname"
                type="text"
              />
              @if ((form.get('firstName')?.dirty || submitted) &&
              form.get('firstName')?.hasError('required')) {
                <div class="mt-2 text-sm text-red-600">Verplicht</div>
              }
            </div>
          </div>

          <div class="col-span-full sm:col-span-6">
            <label class="block text-sm font-medium text-gray-700" for="surname"
            >Achternaam</label
            >
            <div class="mt-1">
              <input
                autocomplete="address-level2"
                class="block w-full border-gray-300 rounded shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                formControlName="lastName"
                id="surname"
                name="surname"
                type="text"
              />
              @if ((form.get('lastName')?.dirty || submitted) &&
              form.get('lastName')?.hasError('required')) {
                <div class="mt-2 text-sm text-red-600">Verplicht</div>
              }
            </div>
          </div>

          <div class="col-span-full">
            <label class="block text-sm font-medium text-gray-700" for="phone"
            >Telefoonnummer</label
            >
            <div class="mt-1">
              <input
                autocomplete="phone"
                class="block w-full border-gray-300 rounded shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                formControlName="phone"
                id="phone"
                name="phone"
                type="text"
              />
            </div>
          </div>

          <div class="col-span-full sm:col-span-6">
            <label class="block text-sm font-medium text-gray-700" for="zip"
            >Postcode</label
            >
            <div class="mt-1">
              <input
                autocomplete="address-level2"
                class="block w-full border-gray-300 rounded shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                formControlName="postalCode"
                id="zip"
                name="zip"
                type="text"
              />
              @if ((form.get('postalCode')?.dirty || submitted) &&
              form.get('postalCode')?.hasError('required')) {
                <div class="mt-2 text-sm text-red-600">Verplicht</div>
              }
            </div>
          </div>

          <div class="col-span-full sm:col-span-6">
            <label
              class="block text-sm font-medium text-gray-700"
              for="houseNumber"
            >Huisnummer</label
            >
            <div class="mt-1">
              <input
                autocomplete="address-level2"
                class="block w-full border-gray-300 rounded shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                formControlName="streetNr"
                id="houseNumber"
                name="houseNumber"
                type="text"
              />
              @if ((form.get('streetNr')?.dirty || submitted) &&
              form.get('streetNr')?.hasError('required')) {
                <div class="mt-2 text-sm text-red-600">Verplicht</div>
              }
            </div>
          </div>

          <div class="col-span-full">
            <label class="block text-sm font-medium text-gray-700" for="street"
            >Straat</label
            >
            <div class="mt-1">
              <input
                autocomplete="street"
                class="block w-full border-gray-300 rounded shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                formControlName="street"
                id="street"
                name="street"
                type="text"
              />
              @if ((form.get('street')?.dirty || submitted) &&
              form.get('street')?.hasError('required')) {
                <div class="mt-2 text-sm text-red-600">Verplicht</div>
              }
            </div>
          </div>

          <div class="col-span-full">
            <label class="block text-sm font-medium text-gray-700" for="place"
            >Woonplaats</label
            >
            <div class="mt-1">
              <input
                autocomplete="place"
                class="block w-full border-gray-300 rounded shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                formControlName="place"
                id="place"
                name="place"
                type="text"
              />
              @if ((form.get('place')?.dirty || submitted) &&
              form.get('place')?.hasError('required')) {
                <div class="mt-2 text-sm text-red-600">Verplicht</div>
              }
            </div>
          </div>

          <div class="col-span-full">
            <label
              [for]="'country'"
              class="block text-sm font-medium text-gray-700"
            >Land</label
            >
            <div class="mt-1">
              <shared-select
                (ngModelChange)="onChangeCountry($event)"
                [dataSource]="countryDataSource"
                [id]="'country'"
                [name]="'country'"
                formControlName="country"
              ></shared-select>
            </div>
          </div>

          <div class="col-span-full">
            <label class="block text-sm font-medium text-gray-700 mb-1"
            >Betaalwijze</label
            >
            <div class="divide-y border-t border-b">
              @for (paymentMethod of paymentMethods; track paymentMethod) {
                <label
                  [for]="paymentMethod.value"
                  class="relative items-center flex pb-2 pt-2 cursor-pointer"
                >
                  <div class="flex h-6 items-center">
                    <input
                      [id]="paymentMethod.value"
                      [value]="paymentMethod.value"
                      class="cursor-pointer h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600"
                      formControlName="paymentMethod"
                      type="radio"
                    />
                  </div>
                  <div class="ml-3">
                    <img
                      [src]="paymentMethod.image"
                      alt=""
                      class="h-10 w-10 rounded-sm"
                    />
                  </div>
                  <div class="ml-3 text-sm leading-6">
                    <p class="font-medium text-gray-900 cursor-pointer">
                      {{ paymentMethod.name }}
                    </p>
                    <p class="text-gray-500">
                      @if (paymentMethod.surchargePercentage) {
                        <span class="text-gray-500"
                        >{{ paymentMethod.surchargePercentage }}% extra
                          kosten</span
                        >
                      }
                      {{ paymentMethod.description }}
                    </p>
                  </div>
                </label>
              }
            </div>
          </div>

          <div class="col-span-full">
            <div class="mt-1">
              <shared-checkbox
                [label]="'Aanmelden voor nieuwsbrief'"
                formControlName="newsletter"
              ></shared-checkbox>
            </div>
          </div>
        </div>

        <button
          [disabled]="isDisabled"
          class="w-full mt-6 disabled:bg-primary-400 bg-primary-500 border border-transparent rounded shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          type="submit"
        >
          Betalen
        </button>

        <p class="flex justify-center text-sm font-medium text-gray-500 mt-6">
          <!-- Heroicon name: solid/lock-closed -->
          <svg
            aria-hidden="true"
            class="w-5 h-5 text-gray-400 mr-1.5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
              fill-rule="evenodd"
            />
          </svg>
          Betaal via een beveiligde verbinding
        </p>
      </form>
    </div>
  </section>
</main>

/* eslint-disable */
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Page } from './../../core/models/page.model';
import { MetadataService } from './../../core/services/metadata/metadata.service';
import { PageService } from './../../core/services/page/page.service';
import {
  CatalogCategory,
  CatalogCategoryProduct,
} from './catalog-category-models';
import { CatalogCategoryService } from './catalog-category.service';
import { BreadcrumbDatasource } from '@shared/ui/breadcrumb';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'shop-catalog-category',
  templateUrl: './catalog-category.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogCategoryComponent implements OnInit {
  products: Array<CatalogCategoryProduct> = [];
  navigation: Array<any> = [];
  page?: Page;
  breadcrumbDataSource?: BreadcrumbDatasource;
  isBrand = false;

  previousCategoryName?: string;
  private previousCategoryUrl?: string;

  constructor(
    private catalogCategoryService: CatalogCategoryService,
    private route: ActivatedRoute,
    private pageService: PageService,
    private metadataService: MetadataService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {}

  onPreviousCategory(): void {
    this.router.navigateByUrl(this.previousCategoryUrl as string);
  }

  onBreadcrumbNavigate(value: string) {
    this.router.navigateByUrl(value);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params['category'] !== undefined) {
        this.isBrand = false;
        this.loadProductsByCategory(params['category']);
        this.loadPageByCategory(params['category']);
      } else {
        this.isBrand = true;
        this.loadProductsByBrand(params['brand']);
        this.loadPageByBrand(params['brand']);
      }
    });
  }

  /**
   * Load Products by Category Url.
   *
   * @private
   * @param {string} category
   *
   * @memberOf CatalogCategoryComponent
   */
  private loadProductsByCategory(category: string) {
    // TODO: Create Object for Filtering.
    let isSale = false;
    let isNew = false;

    if (category === 'sale') {
      isSale = true;
      // @ts-ignore
      category = null;
    }

    if (category === 'new') {
      isNew = true;
      // @ts-ignore
      category = null;
    }

    const object = {
      category: category,
      isSale: isSale,
      isNew: isNew,
    };

    this.catalogCategoryService
      .getByCategory(object)
      .subscribe((res: CatalogCategory) => {
        this.products = res.products;
        this.navigation = res.navigation;
      });
  }

  /**
   * Load Products by Brand Url.
   *
   * @private
   * @param {string} category
   *
   * @memberOf CatalogCategoryComponent
   */
  private loadProductsByBrand(brand: string) {
    this.catalogCategoryService
      .getByBrand(brand)
      .subscribe((res: CatalogCategory) => {
        this.products = res.products;
        this.navigation = res.navigation;
      });
  }

  /**
   * Load Title and Text.
   *
   * @private
   * @param {string} category
   *
   * @memberOf CatalogCategoryComponent
   */
  private loadPageByCategory(category: string) {
    this.pageService.get(category).subscribe((res: Page) => {
      this.page = res;

      this.breadcrumbDataSource = [
        {
          value: '/',
        },
      ];

      if (this.page.parent) {
        this.breadcrumbDataSource.push({
          value: '/category/' + this.page.parent.id,
          name: this.page.parent.name,
        });

        this.previousCategoryName = this.page.parent.name;
        this.previousCategoryUrl = '/category/' + this.page.parent.id;
      } else {
        this.previousCategoryName = undefined;
        this.previousCategoryUrl = undefined;
      }

      this.breadcrumbDataSource.push({
        name: this.page.name,
      });

      this.cdr.detectChanges();
    });
  }

  /**
   * Load Title and Text.
   *
   * @private
   * @param {string} category
   *
   * @memberOf CatalogCategoryComponent
   */
  private loadPageByBrand(brand: string) {
    this.catalogCategoryService.getPageByBrand(brand).subscribe((res: Page) => {
      this.page = res;
      this.metadataService.setTag('title', res.name);

      this.breadcrumbDataSource = [
        {
          value: '/',
        },
        {
          value: '/brands',
          name: 'Merken',
        },
      ];

      this.previousCategoryName = 'Merken';
      this.previousCategoryUrl = '/brands';

      this.breadcrumbDataSource.push({
        name: this.page.name,
      });

      this.cdr.detectChanges();
    });
  }
}

import { Offsets } from '../models';

import { getClientRect } from './getClientRect';
import { getOffsetRectRelativeToArbitraryNode } from './getOffsetRectRelativeToArbitraryNode';
import { getScroll } from './getScroll';

export function getViewportOffsetRectRelativeToArtbitraryNode(
  element: HTMLElement,
  excludeScroll = false,
): Offsets {
  const html = element.ownerDocument.documentElement;
  const relativeOffset = getOffsetRectRelativeToArbitraryNode(element, html);
  const width = Math.max(html.clientWidth, window.innerWidth || 0);
  const height = Math.max(html.clientHeight, window.innerHeight || 0);

  const scrollTop = !excludeScroll ? getScroll(html) : 0;
  const scrollLeft = !excludeScroll ? getScroll(html, 'left') : 0;

  const offset = {
    top:
      scrollTop -
      Number(relativeOffset?.top) +
      Number(relativeOffset?.marginTop),
    left:
      scrollLeft -
      Number(relativeOffset?.left) +
      Number(relativeOffset?.marginLeft),
    width,
    height,
  };

  return getClientRect(offset);
}

/* eslint-disable */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogCategoryProduct } from '../catalog-category/catalog-category-models';
import { CatalogCheckoutService } from '../shared/services/catalog-checkout.service';
import { CatalogLastViewedService } from '../shared/services/catalog-last-viewed.service';

import { MetadataService } from './../../core/services/metadata/metadata.service';
import { CatalogProduct } from './catalog-product-models';
import { CatalogProductService } from './catalog-product.service';
import { BaseModal, ModalRefService, ModalService } from '@shared/ui-modal';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastService } from '@shared/ui-toast';
import { BreadcrumbDatasource } from '@shared/ui/breadcrumb';

@Component({
  selector: 'shop-catalog-product-notify-modal',
  template: `
    <form (ngSubmit)="onSubmit()" [formGroup]="form" novalidate>
      <shared-modal-header
        [title]="'Houd mij op de hoogte'"
      ></shared-modal-header>
      <shared-modal-body>
        <p>We sturen je een mail als we dit artikel weer op voorraad hebben.</p>
        <label class="block text-sm font-medium text-gray-700" for="firstname"
          >E-mailadres</label
        >
        <div class="mt-1">
          <input
            autocomplete="mail"
            class="block w-full border-gray-300 rounded shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
            formControlName="mail"
            id="mail"
            name="mail"
            type="text"
          />
          @if (
            (form.get('mail')?.dirty || submitted) &&
            form.get('mail')?.hasError('required')
          ) {
            <div class="mt-2 text-sm text-red-600">Verplicht</div>
          }
        </div>
      </shared-modal-body>
      <shared-modal-footer>
        <shared-button-holder>
          <shared-button (click)="onSubmit()" [appearance]="'primary'">
            Geef mij een seintje
          </shared-button>
        </shared-button-holder>
      </shared-modal-footer>
    </form>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductNotifyModalComponent extends BaseModal<CatalogProductNotifyModalComponent> {
  form = this.fb.group({
    mail: [null, Validators.required],
  });
  submitted?: boolean;

  constructor(
    public override bsModalRef: ModalRefService,
    private readonly fb: FormBuilder,
    private readonly catalogProductService: CatalogProductService,
    private toastService: ToastService,
  ) {
    super(bsModalRef);
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      this.catalogProductService
        .notifyProduct(
          window.location.pathname.substring(
            window.location.pathname.lastIndexOf('/') + 1,
          ),
          this.form.value.mail as unknown as string,
        )
        .subscribe(() => {
          this.toastService.success(
            'Succesvol aangemeld',
            'Wij houden je op de hoogte',
          );
          this.onClose(true);
        });
    }
  }
}

@Component({
  templateUrl: './catalog-product.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogProductComponent implements OnInit, AfterViewInit {
  breadcrumbDataSource?: BreadcrumbDatasource;
  // @ts-ignore
  activeImageIndex = 0;
  // @ts-ignore
  product: any; //CatalogProduct;
  shortText?: string;
  // @ts-ignore
  stock: number;
  stocks = Array;
  // @ts-ignore
  sum: number;
  discount?: string;
  shareUrl = '';
  // @ts-ignore
  commentUrl;
  combination = '';
  // @ts-ignore
  deliveryTime: string;
  // @ts-ignore
  lastViewed: Array<CatalogCategoryProduct>;
  url = ''; // ENVIRONMENT.url;
  openedSection = 'product';
  sumDataSource?: Array<{ value: number; label: string }>;
  previousCategoryName?: string;
  private previousCategoryUrl?: string;

  constructor(
    private catalogCheckoutService: CatalogCheckoutService,
    private catalogProductService: CatalogProductService,
    private route: ActivatedRoute,
    private router: Router,
    private metadataService: MetadataService,
    private catalogLastViewedService: CatalogLastViewedService,
    private readonly modalService: ModalService,
    private readonly cdr: ChangeDetectorRef,
    private readonly toastService: ToastService,
  ) {}

  onPreviousCategory(): void {
    this.router.navigateByUrl(this.previousCategoryUrl as string);
  }

  onBreadcrumbNavigate(value: string) {
    this.router.navigateByUrl(value);
  }

  ngOnInit() {
    // Scroll to top when you navigate to a new procuct and are already on a product page
    this.route.params.subscribe((e) => {
      window.scrollTo(0, 0);
    });

    this.commentUrl = window.location.href;

    this.lastViewed = Object.assign(
      [],
      this.catalogLastViewedService.lastViewed,
    );
    this.route.params.subscribe((params) => {
      this.catalogProductService
        .get(params['product'])
        .subscribe((res: CatalogProduct) => {
          /* tslint:disable */
          this.shareUrl = window.location.href;
          /* tslint:enable */

          this.product = res;
          this.shortText = this.truncateHtml(res.text);
          // @ts-ignore
          this.deliveryTime = this.getDeliveryTime(this.product.deliveryTime);

          if (this.product.salePrice) {
            const a = this.product.salePrice - this.product.price;
            const b = this.product.salePrice / a;
            const c = 100 / b;
            this.discount = c.toFixed(0);
          }

          this.catalogLastViewedService.add({
            name: this.product.name,
            brand: this.product.brand.name,
            image: this.product.images[0],
            imageHover: this.product.images[1],
            price: this.product.price,
            // @ts-ignore
            salePrice: this.product.salePrice,
            url: this.product.url,
            stock: this.product.stock,
          });

          if (!(res.combinations?.length > 0)) {
            // @ts-ignore
            this.stock = this.product.stock as Array<number>;
            const sumDataSource: any[] = [];
            for (let i = 0; i < this.stock; i++) {
              sumDataSource?.push({
                value: i + 1,
                label: (i + 1).toString(),
              });
            }

            this.sumDataSource = sumDataSource;
          }

          this.metadataService.setTag('title', this.product.name);
          this.metadataService.setTag('og:type', 'product');
          // this.metadataService.setTag('og:image', todo);
          // <meta property="product:original_price:amount"   content="Sample Original Price: " />
          // <meta property="product:original_price:currency" content="Sample Original Price: " />
          // <meta property="product:pretax_price:amount"     content="Sample Pre-tax Price: " />
          // <meta property="product:pretax_price:currency"   content="Sample Pre-tax Price: " />
          // <meta property="product:price:amount"            content="Sample Price: " />
          // <meta property="product:price:currency"          content="Sample Price: " />
          // <meta property="product:shipping_cost:amount"    content="Sample Shipping Cost: " />
          // <meta property="product:shipping_cost:currency"  content="Sample Shipping Cost: " />
          // <meta property="product:weight:value"            content="Sample Weight: Value" />
          // <meta property="product:weight:units"            content="Sample Weight: Units" />
          // <meta property="product:shipping_weight:value"   content="Sample Shipping Weight: Value" />
          // <meta property="product:shipping_weight:units"   content="Sample Shipping Weight: Units" />
          // <meta property="product:sale_price:amount"       content="Sample Sale Price: " />
          // <meta property="product:sale_price:currency"     content="Sample Sale Price: " />
          // <meta property="product:sale_price_dates:start"  content="Sample Sale Price Dates: Start" />
          // <meta property="product:sale_price_dates:end"    content="Sample Sale Price Dates: End" />

          // @ts-ignore
          if (res.stock > 0) {
            this.sum = 1;
          }

          this.breadcrumbDataSource = [
            {
              value: '/',
            },
            ...this.product.categories.map((m: any) => {
              return {
                value: '/category/' + m.id,
                name: m.name,
              };
            }),
            {
              name: this.product.name,
            },
          ];
          const lastCategory = this.product.categories.pop();
          this.previousCategoryName = lastCategory.name;
          this.previousCategoryUrl = '/category/' + lastCategory.id;

          this.cdr.detectChanges();
        });
    });
  }

  onNotify() {
    this.modalService
      .show(CatalogProductNotifyModalComponent, {
        size: 'sm',
      })
      .then((r) => console.log(r));
  }

  ngAfterViewInit() {
    (function (d, s, id) {
      let js = <any>d.getElementsByTagName(s)[0];
      const fjs = d.getElementsByTagName(s)[0];
      js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.4';

      if (d.getElementById(id)) {
        // if <script id="facebook-jssdk"> exists
        delete (<any>window).FB;
        // @ts-ignore
        fjs.parentNode.replaceChild(js, fjs);
      } else {
        // @ts-ignore
        fjs.parentNode.insertBefore(js, fjs);
      }
    })(document, 'script', 'facebook-jssdk');
  }

  onChangeCombination(sku: string) {
    const combination = this.product.combinations?.filter(
      (x: { sku: string }) => x.sku === sku,
    )[0];
    // @ts-ignore
    this.deliveryTime = this.getDeliveryTime(combination.deliveryTime);
    // @ts-ignore
    this.stock = combination.stock;
    if (this.stock > 0) {
      this.sum = 1;
    }
  }

  getDeliveryTime(value: number) {
    switch (value) {
      case 1:
        return 'Direct leverbaar!';
      case 0:
        return null;
      default:
        return `Leverbaar in ${value} dagen`;
    }
  }

  /**
   * Order product.
   *
   * @memberOf CatalogProductComponent
   */
  onOrder() {
    if (this.product.stock === 0) {
      return;
    }

    // Map Product to Product for the Cart / Checkout.
    this.catalogCheckoutService.addProduct({
      url: this.product.url,
      image: this.product.images[0],
      name: this.product.name,
      sum: +this.sum,
      price: this.product.price,
      // @ts-ignore
      salePrice: this.product.salePrice,
      deliveryTime: 1,
      // @ts-ignore
      stock: this.product.stock,
      brand: this.product.brand,
    });

    // this.router.navigate(['checkout/cart']);
  }

  truncateHtml(text: string): string {
    let charlimit = 200;
    if (!text || text.length <= charlimit) {
      return text;
    }

    let without_html = text.replace(/<(?:.|\n)*?>/gm, '');
    let shortened = without_html.substring(0, charlimit) + '...';
    return shortened;
  }

  getActiveImage() {
    return this.product?.images[this.activeImageIndex] ?? '';
  }

  /**
   * Set Selected Image.
   *
   * @param {string} imageId
   *
   * @memberOf CatalogProductComponent
   */
  onImageChange(index: number) {
    this.activeImageIndex = index;
  }
}

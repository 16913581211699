import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
} from '@angular/core';

import { Utils } from '@shared/util-utils';

// TODO: Make generic library from this
/** This component will be added as background layout for modals if enabled */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'bs-modal-backdrop',
  template: ' ',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalBackdropComponent implements OnInit {
  element: ElementRef;
  renderer: Renderer2;

  constructor(element: ElementRef, renderer: Renderer2) {
    this.element = element;
    this.renderer = renderer;
  }

  protected _isShown = false;

  get isShown(): boolean {
    return this._isShown;
  }

  set isShown(value: boolean) {
    this._isShown = value;

    if (value) {
      this.renderer.addClass(this.element.nativeElement, `show`);
    } else {
      this.renderer.removeClass(this.element.nativeElement, `show`);
    }
  }

  ngOnInit(): void {
    this.renderer.addClass(this.element.nativeElement, `fade`);
    Utils.reflow(this.element.nativeElement);
    this.isShown = true;
  }
}

import { Injectable, InjectionToken } from '@angular/core';

import { ModalSize } from './models/modal-size.type';

@Injectable()
export class ModalConfigService<T = Record<string, unknown>> {
  /**
   *  Allow user to Id for the modal. Otherwise, a unique number will be given.
   */
  id?: number | string;
  /**
   *  Includes a modal-backdrop element. Alternatively,
   *  specify static for a backdrop which doesn't close the modal on click.
   */
  backdrop?: boolean | 'static';
  /**
   * Closes the modal when escape key is pressed.
   */
  keyboard?: boolean;

  focus?: boolean;
  /**
   * Ignore the backdrop click
   */
  ignoreBackdropClick?: boolean;
  /**
   * Modal data.
   */
  initialState?: Partial<T>;

  /**
   * The initial size of the modals.
   */
  size?: ModalSize;
}

export const modalConfigDefaults: ModalConfigService = {
  backdrop: true,
  keyboard: true,
  focus: true,
  ignoreBackdropClick: false,
  size: 'base',
  initialState: {},
};

export const MODAL_CONFIG: InjectionToken<ModalConfigService> =
  new InjectionToken<ModalConfigService>('MODAL_CONFIG');

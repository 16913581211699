<!--
This example requires Tailwind CSS v2.0+

This example requires some changes to your config:

```
// tailwind.config.js
module.exports = {
// ...
plugins: [
// ...
require('@tailwindcss/typography'),
require('@tailwindcss/aspect-ratio'),
],
}
```
-->
<div class="bg-white">
  <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
    <div
      class="border-b border-gray-200 py-2.5 flex space-x-2 items-center overflow-hidden"
    >
      <shared-button
        (click)="onPreviousCategory()"
        [appearance]="'primary'"
        [icon]="'chevron-left'"
        [size]="'sm'"
      >Terug naar {{ previousCategoryName }}
      </shared-button>
      <shared-breadcrumb
        (navigate)="onBreadcrumbNavigate($event)"
        [dataSource]="breadcrumbDataSource"
      ></shared-breadcrumb>
    </div>
  </div>
  <div class="mx-auto py-10 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
    <div class="md:grid md:grid-cols-2 md:gap-x-8 md:items-start">
      <!-- Image gallery -->
      <div class="flex flex-col-reverse">
        <!-- Image selector -->
        <div
          class="mt-6 w-full max-w-2xl mx-auto block lg:max-w-none"
        >
          <div
            aria-orientation="horizontal"
            class="grid grid-cols-2 sm:grid-cols-4 gap-4 sm:gap-6"
            role="tablist"
          >
            @for (image of product?.images; track image; let index = $index) {
              <button
                (click)="onImageChange(index)"
                aria-controls="tabs-1-panel-1"
                class="relative h-24 bg-white rounded flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50"
                id="tabs-1-tab-1"
                role="tab"
                type="button"
              >
                <span class="sr-only"> Angled view </span>
                <span class="absolute inset-0 rounded overflow-hidden">
                  @if (image) {
                    <img
                      alt=""
                      class="w-full h-full object-center object-cover"
                      src="{{ url + image }}"
                    />
                  }
                </span>
                <!-- Selected: "ring-primary-500", Not Selected: "ring-transparent" -->
                <span
                  aria-hidden="true"
                  class="ring-transparent absolute inset-0 rounded ring-2 ring-offset-2 pointer-events-none"
                ></span>
              </button>
            }

            <!-- More images... -->
          </div>
        </div>

        <div class="w-full aspect-w-1 aspect-h-1">
          <!-- Tab panel, show/hide based on tab state. -->
          <div
            aria-labelledby="tabs-1-tab-1"
            id="tabs-1-panel-1"
            role="tabpanel"
            tabindex="0"
          >
            @if (getActiveImage()) {
              <img
                alt=""
                class="w-full h-full object-center object-cover sm:rounded"
                src="{{ url + getActiveImage() }}"
              />
            }
          </div>

          <!-- More images... -->
        </div>
      </div>

      <!-- Product info -->
      <div class="flex-col mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
        <h1 class="text-lg font-semibold tracking-tight text-gray-900">
          {{ product?.name }}
        </h1>

        <div class="mt-3 text-3xl">
          <span
            [ngClass]="{ 'text-red-600': product?.salePrice !== null }"
            class="font-medium text-gray-900"
          >
            {{ product?.price | currency: 'EUR' : 'symbol' : '1.2-2' }}
          </span>
          @if (product?.salePrice !== null) {
            <span
              [ngClass]="{
                'line-through text-gray-500 ml-1': product?.salePrice !== null
              }"
            >
              {{ product?.salePrice | currency: 'EUR' : 'symbol' : '1.2-2' }}
            </span>
          }
        </div>

        @if (product?.salePrice !== null) {
          <div class="mb-3 mt-2">
            <span class="text-white rounded p-1 bg-red-600 leading-none"
            >-{{ discount }}%</span
            >
          </div>
        }

        <!-- Brand-->
        <div class="mt-3">
          <a [routerLink]="'/brand/' + product?.brand.url" class="inline-block">
            @if (product !== undefined) {
              <img
                alt="{{ product?.brand.name }}"
                class="w-20 h-20 rounded-sm"
                src="{{ url }}/storage/brands/{{ product?.brand?.image }}"
              />
            }
          </a>
        </div>

        <!-- Reviews -->
        <!--        <div class="mt-3">-->
        <!--          <h3 class="sr-only">Reviews</h3>-->
        <!--          <div class="flex items-center">-->
        <!--            <div class="flex items-center">-->
        <!--              &lt;!&ndash;-->
        <!--                Heroicon name: solid/star-->

        <!--                Active: "text-primary-500", Inactive: "text-gray-300"-->
        <!--              &ndash;&gt;-->
        <!--              <svg aria-hidden="true" class="h-5 w-5 flex-shrink-0 text-primary-500" fill="currentColor"-->
        <!--                   viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
        <!--                <path-->
        <!--                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />-->
        <!--              </svg>-->

        <!--              &lt;!&ndash; Heroicon name: solid/star &ndash;&gt;-->
        <!--              <svg aria-hidden="true" class="h-5 w-5 flex-shrink-0 text-primary-500" fill="currentColor"-->
        <!--                   viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
        <!--                <path-->
        <!--                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />-->
        <!--              </svg>-->

        <!--              &lt;!&ndash; Heroicon name: solid/star &ndash;&gt;-->
        <!--              <svg aria-hidden="true" class="h-5 w-5 flex-shrink-0 text-primary-500" fill="currentColor"-->
        <!--                   viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
        <!--                <path-->
        <!--                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />-->
        <!--              </svg>-->

        <!--              &lt;!&ndash; Heroicon name: solid/star &ndash;&gt;-->
        <!--              <svg aria-hidden="true" class="h-5 w-5 flex-shrink-0 text-primary-500" fill="currentColor"-->
        <!--                   viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
        <!--                <path-->
        <!--                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />-->
        <!--              </svg>-->

        <!--              &lt;!&ndash; Heroicon name: solid/star &ndash;&gt;-->
        <!--              <svg aria-hidden="true" class="h-5 w-5 flex-shrink-0 text-gray-300" fill="currentColor"-->
        <!--                   viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
        <!--                <path-->
        <!--                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />-->
        <!--              </svg>-->
        <!--            </div>-->
        <!--            <p class="sr-only">4 out of 5 stars</p>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="mt-3">
          <h3 class="sr-only">Description</h3>

          <div class="text-base text-gray-700 space-y-6">
            @if (shortText) {
              <div [innerHTML]="shortText | safe: 'html'"></div>
            }
          </div>
        </div>

        @if (product?.stock === 0) {
          <shared-alert [appearance]="'warning'" class="mt-6 block"
          >Het gewenste product is helaas niet meer op voorraad!
          </shared-alert>
        }
        @if (product?.stock === 0) {
          <button
            (click)="onNotify()"
            class="w-full mt-3 flex-row bg-primary-500 border border-transparent rounded py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500 sm:w-full"
            type="submit"
          >
            <shared-icon
              [name]="'envelope'"
              [size]="'2xl'"
              class="mr-2"
            ></shared-icon>
            Geef een seintje
          </button>
        }
        @if (product?.stock > 0) {
          <form class="mt-6">
            <p class="mb-6 flex text-sm text-gray-700 space-x-2">
              <!-- Heroicon name: solid/check -->
              <svg
                aria-hidden="true"
                class="flex-shrink-0 h-5 w-5 text-green-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  fill-rule="evenodd"
                />
              </svg>
              <span class="text-green-600">Voor 23:59 besteld, morgen verstuurd</span>
              <shared-icon class="text-gray-500" [sharedTooltip]="tooltipDelivery"
                           [name]="'info-square-fill'"></shared-icon>
              <ng-template #tooltipDelivery>
                <strong>Levertijd</strong><br /><br />
                We doen er alles aan om dit artikel op tijd te<br />
                bezorgen. Het is echter in een enkel geval<br />
                mogelijk dat door omstandigheden de<br />
                bezorging vertraagd is.
              </ng-template>
            </p>
            <!-- Sum -->
            <div>
              <h3 class="text-sm text-gray-600">Aantal</h3>
              <div class="sm:flex-col1">
                <shared-select
                  [(ngModel)]="sum"
                  [dataSource]="sumDataSource"
                  [id]="'sum'"
                  [name]="'sum'"
                  class="flex max-w-fit flex-1 mt-2"
                ></shared-select>
              </div>
            </div>
            <div class="mt-10 flex-col1">
              <span
                class="flex justify-center mb-1 text-sm w-full flex-row text-red-500 font-bold"
              >Gratis verzending vanaf 100 euro in NL!</span
              >
              <button
                (click)="onOrder()"
                class="w-full flex-row bg-primary-500 border border-transparent rounded py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500 sm:w-full"
                type="submit"
              >
                <shared-icon
                  [name]="'cart'"
                  [size]="'2xl'"
                  class="mr-2"
                ></shared-icon>
                In winkelwagen
              </button>
            </div>
          </form>
        }

        <section aria-labelledby="details-heading" class="mt-12">
          <h2 class="sr-only" id="details-heading">Additional details</h2>

          <div class="border-t divide-y divide-gray-200">
            <!-- More about this product-->
            <div>
              <h3>
                <!-- Expand/collapse question button -->
                <button
                  (click)="
                    openedSection === 'product'
                      ? (openedSection = '')
                      : (openedSection = 'product')
                  "
                  aria-controls="disclosure-1"
                  aria-expanded="false"
                  class="group relative w-full py-6 flex justify-between items-center text-left"
                  type="button"
                >
                  <!-- Open: "text-primary-600", Closed: "text-gray-900" -->
                  @if (openedSection === 'product') {
                    <span class="text-primary-600 text-sm font-medium">
                      Alles over dit product
                    </span>
                  }
                  @if (openedSection !== 'product') {
                    <span class="text-gray-900 text-sm font-medium">
                      Alles over dit product
                    </span>
                  }
                  <span class="ml-6 flex items-center">
                    <!--
            Heroicon name: outline/plus-sm

            Open: "hidden", Closed: "block"
            -->
                    @if (openedSection !== 'product') {
                      <svg
                        aria-hidden="true"
                        class="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </svg>
                    }
                    <!--
            Heroicon name: outline/minus-sm

            Open: "block", Closed: "hidden"
            -->
                    @if (openedSection === 'product') {
                      <svg
                        aria-hidden="true"
                        class="block h-6 w-6 text-primary-400 group-hover:text-primary-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 12H6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </svg>
                    }
                  </span>
                </button>
              </h3>
              <div
                [class.hidden]="openedSection !== 'product'"
                class="pb-6 text-sm"
                id="disclosure-1"
              >
                <ul class="pb-6 flex flex-col space-y-1" role="list">
                  <li><strong>Artikelnummer:</strong> {{ product?.sku }}</li>
                  <li><strong>Merk:</strong> {{ product?.brand.name }}</li>
                </ul>

                <div [innerHTML]="product?.text | safe: 'html'"></div>
              </div>
            </div>

            <!-- Tags -->
            <div>
              <h3>
                <!-- Expand/collapse question button -->
                <button
                  (click)="
                    openedSection === 'tags'
                      ? (openedSection = '')
                      : (openedSection = 'tags')
                  "
                  aria-controls="disclosure-1"
                  aria-expanded="false"
                  class="group relative w-full py-6 flex justify-between items-center text-left"
                  type="button"
                >
                  <!-- Open: "text-primary-600", Closed: "text-gray-900" -->
                  @if (openedSection === 'tags') {
                    <span class="text-primary-600 text-sm font-medium">
                      Tags
                    </span>
                  }
                  @if (openedSection !== 'tags') {
                    <span class="text-gray-900 text-sm font-medium">
                      Tags
                    </span>
                  }
                  <span class="ml-6 flex items-center">
                    <!--
            Heroicon name: outline/plus-sm

            Open: "hidden", Closed: "block"
            -->
                    @if (openedSection !== 'tags') {
                      <svg
                        aria-hidden="true"
                        class="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </svg>
                    }
                    <!--
            Heroicon name: outline/minus-sm

            Open: "block", Closed: "hidden"
            -->
                    @if (openedSection === 'tags') {
                      <svg
                        aria-hidden="true"
                        class="block h-6 w-6 text-primary-400 group-hover:text-primary-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 12H6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </svg>
                    }
                  </span>
                </button>
              </h3>
              <div
                [class.hidden]="openedSection !== 'tags'"
                class="pb-6 text-sm"
                id="disclosure-2"
              >
                @for (tag of product?.tags; track tag) {
                  <shared-badge
                    [appearance]="'secondary'"
                    [size]="'base'"
                    class="mr-1 mb-1"
                  >{{ tag.name }}
                  </shared-badge>
                }
                @if (product?.tags?.length === 0) {
                  <p>Geen tags beschikbaar</p>
                }
              </div>
            </div>

            <!-- Share-->
            <div class="pt-6">
              <h3 class="text-sm font-medium text-gray-900">Delen</h3>
              <ul class="flex items-center space-x-6 mt-4" role="list">
                <li>
                  <a
                    class="fb-xfbml-parse-ignore flex items-center justify-center w-6 h-6 text-gray-400 hover:text-gray-500"
                    href="https://www.facebook.com/sharer/sharer.php?u={{
                      shareUrl
                    }}%2F&amp;src=sdkpreparse"
                    target="_blank"
                  >
                    <span class="sr-only">Share on Facebook</span>
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    class="flex items-center justify-center w-6 h-6 text-gray-400 hover:text-gray-500"
                    href="#"
                  >
                    <span class="sr-only">Share on Instagram</span>
                    <svg
                      aria-hidden="true"
                      class="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    class="flex items-center justify-center w-6 h-6 text-gray-400 hover:text-gray-500"
                    href="https://twitter.com/share?ref_src=twsrc%5Etfw"
                    target="_blank"
                  >
                    <span class="sr-only">Share on Twitter</span>
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>

    @if (product?.relatedProducts.length > 0) {
      <div class="mb-6">
        <div
          class="border-b mb-6 border-gray-200 py-2.5 flex space-x-2 items-center overflow-hidden"
        >
          <h1 class="text-lg font-semibold tracking-tight text-gray-900">
            Aanbevolen artikelen
          </h1>
        </div>
        <div
          class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-y-6 gap-x-4"
        >
          @for (product of product?.relatedProducts;
            track product;
            let index = $index) {
            <shop-catalog-product-thumbnail
              [dataSource]="product"
            ></shop-catalog-product-thumbnail>
          }
        </div>
      </div>
    }

    @if (lastViewed && lastViewed.length > 0) {
      <div>
        <div
          class="border-b mb-6 border-gray-200 py-2.5 flex space-x-2 items-center overflow-hidden"
        >
          <h1 class="text-lg font-semibold tracking-tight text-gray-900">
            Laatst bekeken artikelen
          </h1>
        </div>
        <div
          class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-y-6 gap-x-4"
        >
          @for (product of lastViewed; track product; let index = $index) {
            <shop-catalog-product-thumbnail
              [dataSource]="product"
            ></shop-catalog-product-thumbnail>
          }
        </div>
      </div>
    }
  </div>

  <!--<div class="container">-->
  <!--  <div class="row">-->
  <!--    <div class="col-sm-2">-->
  <!--      <div class="row">-->
  <!--        <div class="col-sm-12 mb-1" *ngFor="let image of product?.images; let i = index">-->
  <!--          <img (click)="onImageChange(i)" src="{{url + image}}" class="img-fluid">-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->

  <!--    <div class="col-12 col-sm-5">-->
  <!--      <div class="row">-->
  <!--        <div class="col-12">-->
  <!--&lt;!&ndash;          <ngb-carousel [activeId]="activeImageId" [interval]="0">&ndash;&gt;-->
  <!--&lt;!&ndash;            <ng-template ngbSlide [id]="i" *ngFor="let image of product?.images; let i = index">&ndash;&gt;-->
  <!--&lt;!&ndash;              <img [src]="url + image" class="w-100">&ndash;&gt;-->
  <!--&lt;!&ndash;            </ng-template>&ndash;&gt;-->
  <!--&lt;!&ndash;          </ngb-carousel>&ndash;&gt;-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="col-xs-12 col-sm-5">-->
  <!--      <h3>{{product?.name}}</h3>-->
  <!--      <div class="text-medium mb-2">-->
  <!--        Artikelnummer: {{product?.sku}}</div>-->
  <!--      <p>-->
  <!--        <a class="more" [routerLink]="'/brand/' + product?.brand.url">-->
  <!--          <img *ngIf="product !== undefined" src="{{url}}brands/{{product?.brand?.image}}" width="80px" height="80px" alt="{{product?.brand.name}}">-->
  <!--        </a>-->

  <!--      </p>-->

  <!--      <div class="price-wrap">-->
  <!--        <span [ngClass]="{'price-old': product?.salePrice !== null }" *ngIf="product?.salePrice !== null">€ {{product?.salePrice}}</span>-->
  <!--        <span class="new-price" [ngClass]="{'price-sale': product?.salePrice !== null }">€ {{product?.price}}</span>-->
  <!--      </div>-->

  <!--      <div *ngIf="product?.stock !== 0">-->
  <!--        <div class="form-group row" *ngIf="product?.combinations?.length > 0">-->
  <!--          <label for="example-number-input" class="col-xs-12 col-form-label">Maat</label>-->
  <!--          <div class="col-xs-12">-->
  <!--            <select class="form-control" [(ngModel)]="combination" (ngModelChange)="onChangeCombination($event)">-->
  <!--              <option value="">Selecteer je maat</option>-->
  <!--              <option *ngFor="let combination of product?.combinations; let i = index" [value]="combination.sku">{{combination.name}} - {{getDeliveryTime(combination.deliveryTime)}}</option>-->
  <!--            </select>-->
  <!--          </div>-->
  <!--        </div>-->

  <!--        <div class="form-group row" *ngIf="combination !== '' || product?.combinations?.length === 0">-->
  <!--          <label for="example-number-input" class="col-12 col-form-label">Aantal</label>-->
  <!--          <div class="col-12">-->
  <!--            <select class="form-control" [(ngModel)]="sum">-->
  <!--              <option *ngFor="let stock of stocks(stock); let i = index" [value]="i + 1">{{i + 1}}</option>-->
  <!--            </select>-->
  <!--          </div>-->
  <!--        </div>-->

  <!--        <p class="delivery-time">{{deliveryTime}}</p>-->

  <!--        <button type="submit" (click)="onOrder()" class="btn btn-primary btn-lg btn-block">-->
  <!--          In winkelwagen-->
  <!--          <i class="fas fa-cart-arrow-down" aria-hidden="true"></i>-->
  <!--        </button>-->
  <!--      </div>-->

  <!--      <div *ngIf="product?.stock === 0">-->
  <!--        <p class="delivery-time">{{deliveryTime}}</p>-->

  <!--        <div class="alert alert-warning" role="alert">-->
  <!--          Het gewenste product is helaas niet meer op voorraad!-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      <div class="extra-info mt-2 mb-2">-->
  <!--        <div class="call">-->
  <!--          <i class="fas fa-truck"></i> Ontvang je jouw pakket binnen 1 a 3 dagen!-->
  <!--        </div>-->
  <!--        <div class="call">-->
  <!--          <i class="fas fa-reply-all"></i> Retour binnen 14 dagen </div>-->
  <!--      </div>-->

  <!--      <iframe [src]="shareUrl | safe: 'resourceUrl'" width="71" height="28" style="border:none;overflow:hidden" scrolling="no"-->
  <!--        frameborder="0" allowTransparency="true"></iframe>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--</div>-->

  <!--&lt;!&ndash; Product info &ndash;&gt;-->
  <!--<div class="product-tabs">-->
  <!--  <div class="container">-->
  <!--&lt;!&ndash;    <ngb-tabset [destroyOnHide]="false">&ndash;&gt;-->
  <!--&lt;!&ndash;      <ngb-tab title="Productomschrijving">&ndash;&gt;-->
  <!--&lt;!&ndash;        <ng-template ngbTabContent>&ndash;&gt;-->
  <!--&lt;!&ndash;          <div [innerHTML]="product?.text | safe: 'html'"></div>&ndash;&gt;-->
  <!--&lt;!&ndash;        </ng-template>&ndash;&gt;-->
  <!--&lt;!&ndash;      </ngb-tab>&ndash;&gt;-->
  <!--&lt;!&ndash;      <ngb-tab title="Tags">&ndash;&gt;-->
  <!--&lt;!&ndash;        <ng-template ngbTabContent>&ndash;&gt;-->
  <!--&lt;!&ndash;          <span class="badge badge-pill badge-light mr-3 p-2" *ngFor="let tag of product?.tags">&ndash;&gt;-->
  <!--&lt;!&ndash;            <i class="fas fa-tag"></i> {{tag.name}}</span>&ndash;&gt;-->
  <!--&lt;!&ndash;          <p *ngIf="product?.tags.length === 0">Geen tags beschikbaar</p>&ndash;&gt;-->
  <!--&lt;!&ndash;        </ng-template>&ndash;&gt;-->
  <!--&lt;!&ndash;      </ngb-tab>&ndash;&gt;-->
  <!--&lt;!&ndash;      <ngb-tab title="Merkdetails">&ndash;&gt;-->
  <!--&lt;!&ndash;        <ng-template ngbTabContent>&ndash;&gt;-->
  <!--&lt;!&ndash;          <div [innerHTML]="product?.brand.content | safe: 'html'"></div>&ndash;&gt;-->
  <!--&lt;!&ndash;        </ng-template>&ndash;&gt;-->
  <!--&lt;!&ndash;      </ngb-tab>&ndash;&gt;-->
  <!--&lt;!&ndash;    </ngb-tabset>&ndash;&gt;-->
  <!--    <div class="title-divider mt-3">-->
  <!--      <span>Reviews</span>-->
  <!--    </div>-->
  <!--    <div class="Reviews">-->
  <!--      <div id="fb-root"></div>-->
  <!--      <div class="fb-comments" [attr.data-href]="commentUrl" data-order-by="reverse_time" data-width="100%" data-numposts="10"></div>-->
  <!--    </div>-->
  <!--    <div class="title-divider mt-3" *ngIf="product?.relatedProducts?.length >0">-->
  <!--      <span>Bijhorende Producten</span>-->
  <!--    </div>-->
  <!--    <div class="row mt-3" *ngIf="product?.relatedProducts?.length >0">-->
  <!--      <div *ngFor="let product of product?.relatedProducts" class="col-md-3">-->
  <!--        <shop-catalog-product-thumbnail [dataSource]="product"></shop-catalog-product-thumbnail>-->
  <!--      </div>-->
  <!--    </div>-->

  <!--    <div class="title-divider mt-3" *ngIf="lastViewed.length >0">-->
  <!--      <span>Laatst bekeken producten</span>-->
  <!--    </div>-->
  <!--    <div class="row mt-3" *ngIf="lastViewed.length >0">-->
  <!--      <div *ngFor="let product of lastViewed" class="col-md-3">-->
  <!--        <shop-catalog-product-thumbnail [dataSource]="product"></shop-catalog-product-thumbnail>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</div>

<div class="mb-2">
  @if (title) {
    <h3
      [innerHTML]="title"
      class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300"
      id="modal-title"
    ></h3>
  }
  <ng-content></ng-content>
</div>

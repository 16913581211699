<main class="mx-auto px-4 lg:max-w-7xl lg:px-8">
  <div
    class="border-b border-gray-200 py-2.5 flex space-x-2 items-center overflow-hidden"
  >
    @if (previousCategoryName) {
      <shared-button
        [appearance]="'primary'"
        [icon]="'chevron-left'"
        [size]="'sm'"
        (click)="onPreviousCategory()"
      >Toon alle {{ previousCategoryName }}
      </shared-button>
    }
    <shared-breadcrumb
      (navigate)="onBreadcrumbNavigate($event)"
      [dataSource]="breadcrumbDataSource"
    ></shared-breadcrumb>
  </div>

  <div class="lg:hidden">
    <form class="divide-y divide-gray-200 space-y-10 pt-6">
      <div class="mb-4">
        <h1 class="text-lg font-semibold tracking-tight text-gray-900 mb-2">
          @if (previousCategoryName && !isBrand) {
            {{ previousCategoryName }}
          } @else {
            {{ page?.name }}
          }
        </h1>
        @if (navigation.length > 0) {
          <fieldset>
            <ul class="inline-block pb-6 border-b border-gray-200" role="list">
              @for (n of navigation; track n) {
                <li class="float-left mr-2 mb-2">
                  <shared-button
                    [routerLinkActive]="'text-primary-600'"
                    [rounded]="true"
                    [size]="'sm'"
                    [routerLink]="[n.id]"
                    [appearance]="'light'"
                  >{{ n.name }}
                  </shared-button>
                </li>
              }
            </ul>
          </fieldset>
        }
      </div>
    </form>
  </div>

  <div class="lg:pt-12 pb-24 lg:grid md:grid-cols-4 lg:gap-x-8 lg:grid-cols-5">
    <aside>
      <div class="hidden lg:block">
        <form class="divide-y divide-gray-200 space-y-10">
          <div class="mb-4">
            <h1 class="text-lg font-semibold tracking-tight mb-2">
              @if (previousCategoryName && !isBrand) {
                {{ previousCategoryName }}
              } @else {
                {{ page?.name }}
              }
            </h1>
            @if (navigation.length > 0) {
              <fieldset>
                <ul
                  class="text-sm text-gray-900 space-y-4 pb-6 border-b border-gray-200"
                  role="list"
                >
                  @for (n of navigation; track n) {
                    <li>
                      <a
                        class="hover:text-primary-600"
                        [routerLinkActive]="'text-primary-600'"
                        [routerLink]="[n.id]"
                      >{{ n.name }}</a
                      >
                    </li>
                  }
                </ul>
              </fieldset>
            }
          </div>
        </form>

        @if (page) {
          @if (page.image) {
            <div class="w-20 h-20 mb-2 rounded overflow-hidden bg-gray-100">
              <img
                alt="{{ page.name }}"
                class="w-full h-full object-center object-cover"
                src="{{
                  page.image
                }}"
              />
            </div>
          }
          @if (page.content) {
            <p
              [innerHTML]="page.content | safe: 'html'"
              class="text-sm break-words text-gray-500"
            ></p>
          }
        }
      </div>
    </aside>

    <section
      aria-labelledby="product-heading"
      class="mt-6 lg:mt-0 md:col-span-4"
    >
      <div
        class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-y-6 gap-x-4"
      >
        @for (product of products; track product) {
          <shop-catalog-product-thumbnail [dataSource]="product">
          </shop-catalog-product-thumbnail>
        }
      </div>
    </section>

    @if (page) {
      <div class="lg:hidden">
        @if (page.content) {
          <p
            [innerHTML]="page.content | safe: 'html'"
            class="text-sm text-gray-500 mt-6"
          ></p>
        }
        @if (page.image) {
          <div class="w-20 h-20 mt-2 rounded overflow-hidden bg-gray-100">
            <img
              alt="{{ page.name }}"
              class="w-full h-full object-center object-cover"
              src="{{
                page.image
              }}"
            />
          </div>
        }
      </div>
    }
  </div>
</main>

import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { BaseModal } from '../../classes/base-modal/base-modal';
import { ModalRefService } from '../../services/modal-ref.service';

import { ModalInternalModel } from './modal-internal.model';

@Component({
  selector: 'shared-modal-confirm',
  templateUrl: './modal-internal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalInternalComponent
  extends BaseModal<ModalInternalModel>
  implements OnInit
{
  safeHtml?: string | null;
  confirm?: boolean;

  constructor(
    public sanitizer: DomSanitizer,
    protected override readonly bsModalRef: ModalRefService,
  ) {
    super(bsModalRef);
  }

  ngOnInit(): void {
    if (this.modalData === undefined) {
      this.modalData = {};
    }

    if (this.modalData?.body) {
      this.safeHtml = this.sanitizer.sanitize(
        SecurityContext.HTML,
        this.modalData.body,
      );
    }

    if (this.modalData.title === undefined) this.modalData.title = 'Warning';
    if (this.modalData.body === undefined)
      this.modalData.body = 'Are you sure?';
    if (this.modalData.buttonCancel === undefined)
      this.modalData.buttonCancel = 'Cancel';
    if (this.modalData.buttonContinue === undefined)
      this.modalData.buttonContinue = 'Continue';
  }

  onContinue(): void {
    this.onClose(true);
  }

  onCancel(): void {
    this.onClose(false);
  }
}

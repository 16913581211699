<select
  [formControl]="formControl"
  [id]="id"
  [name]="name"
  class="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 dark:border-gray-600 bg-white text-black dark:text-white dark:bg-gray-700 rounded"
>
  @for (item of dataSource; track item) {
    <option [value]="item.value">
      {{ item.label }}
    </option>
  }
</select>

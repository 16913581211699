/**
 * Get offsets to the reference element
 */
import { Offsets } from '../models';

import { findCommonOffsetParent } from './findCommonOffsetParent';
import { getFixedPositionOffsetParent } from './getFixedPositionOffsetParent';
import { getOffsetRectRelativeToArbitraryNode } from './getOffsetRectRelativeToArbitraryNode';

export function getReferenceOffsets(
  target: HTMLElement,
  host: HTMLElement,
  fixedPosition?: boolean,
): Offsets {
  const commonOffsetParent = fixedPosition
    ? getFixedPositionOffsetParent(target)
    : findCommonOffsetParent(target, host);

  return getOffsetRectRelativeToArbitraryNode(
    host,
    commonOffsetParent,
    fixedPosition,
  );
}

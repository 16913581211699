/* eslint-disable */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Page } from './../../core/models/page.model';
import { CatalogCategory } from './catalog-category-models';

@Injectable()
export class CatalogCategoryService {
  private apiUrl = 'catalog/';

  constructor(private http: HttpClient) {}

  getByCategory(filter: any): Observable<CatalogCategory> {
    return this.http.post<CatalogCategory>(
      this.apiUrl + 'get-products-by-category',
      filter,
    );
  }

  getByBrand(url: string): Observable<CatalogCategory> {
    const filter = {
      brands: [url],
    };

    return this.http.post<CatalogCategory>(
      this.apiUrl + 'get-products-by-category',
      filter,
    );
  }

  getPageByBrand(url: string): Observable<Page> {
    return this.http.get<Page>('page/by-brand/' + url);
  }
}

/**
 * Get bounding client rect of given element
 */
import { Offsets } from '../models';

import { getBordersSize } from './getBordersSize';
import { getClientRect } from './getClientRect';
import { getStyleComputedProperty } from './getStyleComputedProperty';
import { getWindowSizes } from './getWindowSizes';
import { isNumber } from './isNumeric';

export function getBoundingClientRect(element: HTMLElement): Offsets {
  const rect: Offsets = element.getBoundingClientRect();

  if (
    !(
      rect &&
      isNumber(rect.top) &&
      isNumber(rect.left) &&
      isNumber(rect.bottom) &&
      isNumber(rect.right)
    )
  ) {
    return rect;
  }

  const result: Offsets = {
    left: rect.left,
    top: rect.top,
    width: rect.right - rect.left,
    height: rect.bottom - rect.top,
  };

  // subtract scrollbar size from sizes
  const sizes =
    element.nodeName === 'HTML'
      ? getWindowSizes(element.ownerDocument)
      : undefined;
  const width =
    sizes?.width ||
    element.clientWidth ||
    (isNumber(rect.right) &&
      isNumber(result.left) &&
      rect.right - result.left) ||
    0;
  const height =
    sizes?.height ||
    element.clientHeight ||
    (isNumber(rect.bottom) &&
      isNumber(result.top) &&
      rect.bottom - result.top) ||
    0;

  let horizScrollbar = element.offsetWidth - width;
  let vertScrollbar = element.offsetHeight - height;

  // if an hypothetical scrollbar is detected, we must be sure it's not a `border`
  // we make this check conditional for performance reasons
  if (horizScrollbar || vertScrollbar) {
    const styles = getStyleComputedProperty(element);
    horizScrollbar -= getBordersSize(styles, 'x');
    vertScrollbar -= getBordersSize(styles, 'y');

    result.width -= horizScrollbar;
    result.height -= vertScrollbar;
  }

  return getClientRect(result);
}

/* eslint-disable */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class CatalogProductService {
  constructor(private http: HttpClient) {}

  /**
   * Get Product by Url.
   *
   * @param {string} url
   * @returns
   *
   * @memberOf CatalogProductService
   */
  get(url: string) {
    return this.http.get<any>(`catalog/product/${url}`);
  }

  notifyProduct(url: string, mail: string) {
    return this.http.get<any>(`catalog/product-notify/${url}/${mail}`);
  }
}

import { ComponentType } from '@angular/cdk/overlay';
import { Injectable, TemplateRef } from '@angular/core';

import { BaseModal } from '../classes/base-modal/base-modal';
import { ModalInternalComponent } from '../components/modal-internal/modal-internal.component';
import { ModalInternalModel } from '../components/modal-internal/modal-internal.model';
import { ModalConfigService } from '../modal-config.service';
import { ModalSize } from '../models/modal-size.type';

import { ModalInternalService } from './modal-internal.service';

@Injectable()
export class ModalService {
  constructor(private readonly bsModalService: ModalInternalService) {}

  confirm(
    modalData: ModalInternalModel,
    size: ModalSize = 'sm',
    ignoreBackdropClick = false,
  ): Promise<boolean> {
    const modal = this.bsModalService.show<ModalInternalComponent>(
      ModalInternalComponent,
      {
        initialState: {
          modalData,
          confirm: true,
        },
        ignoreBackdropClick: ignoreBackdropClick,
        backdrop: true,
        size,
      },
    );

    return new Promise<boolean>((resolve) =>
      modal.content?.closed$.subscribe(resolve as never),
    );
  }

  single(
    modalData: ModalInternalModel,
    size: ModalSize = 'sm',
    ignoreBackdropClick = false,
  ): Promise<boolean> {
    const modal = this.bsModalService.show<ModalInternalComponent>(
      ModalInternalComponent,
      {
        initialState: {
          modalData,
          confirm: false,
        },
        ignoreBackdropClick: ignoreBackdropClick,
        backdrop: true,
        size,
      },
    );

    return new Promise<boolean>((resolve) =>
      modal.content?.closed$.subscribe(resolve as never),
    );
  }

  show<T>(
    component: ComponentType<T> | TemplateRef<T>,
    options?: ModalConfigService,
    data?: unknown,
  ): Promise<unknown> {
    if (options) {
      if (options.initialState) {
        delete options.initialState;
      }
    }

    const modal = this.bsModalService.show<unknown>(component, {
      initialState: { modalData: data },
      id: Math.random(),
      ...options,
    });

    return new Promise<unknown>((resolve) => {
      (modal.content as BaseModal<unknown>).closed$.subscribe(
        (result: unknown) => {
          resolve(result);
        },
      );
    });
  }

  closeAll(): void {
    for (let i = 1; i <= this.bsModalService.getModalsCount(); i++) {
      this.bsModalService.hide(i);
    }
  }
}

<a class="group -m-2 p-2 flex items-center" routerLink="checkout/cart">
  <!-- Heroicon name: outline/shopping-bag -->
  <!--  <svg aria-hidden="true"-->
  <!--       class="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" fill="none"-->
  <!--       stroke="currentColor" viewBox="0 0 24 24"-->
  <!--       xmlns="http://www.w3.org/2000/svg">-->
  <!--    <path d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" stroke-linecap="round" stroke-linejoin="round"-->
  <!--          stroke-width="2" />-->
  <!--  </svg>-->
  <shared-icon
    [name]="'bag'"
    [size]="'2xl'"
    class="text-gray-400 group-hover:text-gray-500"
  ></shared-icon>
  <span
    class="group-hover:text-gray-800 text-gray-700 text-sm ml-2 hidden md:block"
    >Winkelwagen</span
  >
  <span
    class="ml-2 w-8 h-8 text-sm group-hover:bg-primary-300 rounded-full inline-flex items-center justify-center bg-primary-200 group-hover:text-primary-950 text-primary-900 font-bold"
    >{{ totalProducts }}</span
  >
</a>

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ButtonModule } from '@shared/ui-button';
import { IconComponent } from '@shared/ui-icon';
import { ClickOutsideModule } from '@shared/util-click-outside';
import { ComponentLoaderFactory } from '@shared/util-component-loader';
import { FocusTrapModule } from '@shared/util-focus-trap';
import { PositioningService } from '@shared/util-positioning';

import { ModalBackdropComponent } from './components/modal-backdrop/modal-backdrop.component';
import { ModalBodyComponent } from './components/modal-body/modal-body.component';
import { ModalContainerComponent } from './components/modal-container/modal-container.component';
import { ModalFooterComponent } from './components/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { ModalInternalComponent } from './components/modal-internal/modal-internal.component';
import { ModalInternalService } from './services/modal-internal.service';
import { ModalService } from './services/modal.service';

@NgModule({
  imports: [
    FocusTrapModule,
    CommonModule,
    ButtonModule,
    IconComponent,
    ClickOutsideModule,
  ],
  declarations: [
    ModalBackdropComponent,
    ModalContainerComponent,
    ModalInternalComponent,
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
  ],
  exports: [
    ModalBackdropComponent,
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
  ],
})
export class ModalModule {
  static forRoot(): ModuleWithProviders<ModalModule> {
    return {
      ngModule: ModalModule,
      providers: [
        ModalInternalService,
        ModalService,
        ComponentLoaderFactory,
        PositioningService,
      ],
    };
  }
}

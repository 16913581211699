/* eslint-disable */
import { Component, OnInit } from '@angular/core';

import { CheckoutCart } from './../../catalog/shared/models/checkout.model';
import { CatalogCheckoutService } from './../../catalog/shared/services/catalog-checkout.service';

@Component({
  selector: 'shop-catalog-cart',
  templateUrl: './catalog-cart.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogCartComponent implements OnInit {
  // @ts-ignore
  totalProducts: number;
  // @ts-ignore
  private cart: CheckoutCart;

  constructor(private catalogCheckoutService: CatalogCheckoutService) {}

  /**
   * On Initialization.
   *
   * @memberOf CatalogCartComponent
   */
  ngOnInit() {
    this.cart = this.catalogCheckoutService.checkout.cart || [];
    this.getCheckoutSummary();

    this.catalogCheckoutService.eventEmitter.subscribe((o) => {
      this.cart = o.cart;
      this.getCheckoutSummary();
    });
  }

  /**
   * Get Checkout Summary.
   *
   * @private
   *
   * @memberOf CatalogCartComponent
   */
  private getCheckoutSummary() {
    this.totalProducts = 0;

    this.cart.products.forEach((value) => {
      this.totalProducts += value.sum;
    });
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';

import { PlacementForBs5 } from '@shared/util-positioning';

import { TooltipService } from './tooltip.service';

// <div
//   class="
// absolute
// right-0
// w-auto
// p-3
// text-gray-500
// transform
// translate-x-[35%]
// bg-green-100
// rounded
// "
// >
// <div class="relative flex text-black items-center justify-center w-full h-full">
//   Copy to clipboard
// <div
// class="absolute after bottom-0 w-4 h-4 transform rotate-45 translate-y-5 bg-green-100 "
//   ></div>
//   </div>
//   </div>

@Component({
  selector: 'shared-tooltip-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class]':
      '"text-primary background-secondary show tooltip in tooltip-" + placement + " " + "bs-tooltip-" + placement + " " + placement + " " + containerClass',
    '[attr.id]': 'this.id',
    role: 'tooltip',
  },
  styles: [
    `
      :host.tooltip {
        display: block;
        pointer-events: none;

        position: absolute;
        z-index: 1070;
        display: block;
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Poppins,
          'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
          'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
        text-align: left;
        top: -4px !important;
        text-align: start;
        text-decoration: none;
        text-shadow: none;
        text-transform: none;
        padding: 0.4rem 0;
        letter-spacing: normal;
        word-break: normal;
        word-spacing: normal;
        white-space: normal;
        line-break: auto;
        font-size: 0.875rem;
        word-wrap: break-word;
        opacity: 0;
      }

      :host.bs3.tooltip.top > .arrow {
        margin-left: -2px;
      }

      :host.bs3.tooltip.bottom {
        margin-top: 0px;
      }

      :host.tooltip.show {
        opacity: 0.9;
      }

      :host .tooltip-arrow {
        position: absolute;
        display: block;
        width: 0.8rem;
        height: 0.4rem;
        bottom: 0;
      }

      :host .tooltip-arrow::before {
        top: 0;
        border-width: 0.4rem 0.4rem 0;
        position: absolute;
        content: '';
        border-color: transparent;
        border-style: solid;
      }

      :host .tooltip-arrow::before {
        border-top-color: #000;
      }

      :host .tooltip-inner {
        max-width: 200px;
        padding: 0.25rem 0.5rem;
        color: #fff;
        text-align: center;
        background-color: #000;
        border-radius: 0.25rem;
      }

      :host.bs3.bs-tooltip-left,
      :host.bs3.bs-tooltip-right {
        margin: 0px;
      }

      :host.bs3.bs-tooltip-right .arrow,
      :host.bs3.bs-tooltip-left .arrow {
        margin: 0.3rem 0;
      }
    `,
  ],
  template: `
    <div class="tooltip-arrow arrow"></div>
    <div class="tooltip-inner">
      <ng-content></ng-content>
    </div>
  `,
})
export class TooltipContainerComponent implements AfterViewInit {
  classMap?: { [key: string]: boolean };
  placement?: string;
  containerClass?: string;
  animation?: boolean;
  id?: string;

  constructor(config: TooltipService) {
    Object.assign(this, config);
  }

  ngAfterViewInit(): void {
    this.classMap = { in: false, fade: false };
    if (this.placement) {
      this.placement =
        PlacementForBs5[this.placement as keyof typeof PlacementForBs5];

      this.classMap[this.placement] = true;
    }
    this.classMap[`tooltip-${this.placement}`] = true;

    this.classMap['in'] = true;
    if (this.animation) {
      this.classMap['fade'] = true;
    }

    if (this.containerClass) {
      this.classMap[this.containerClass] = true;
    }
  }
}

/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LocalStorageService } from '../../core/services/local-storage/local-storage.service';
import { CatalogCheckoutInformationService } from '../catalog-checkout-information/catalog-checkout-information.service';
import { CatalogCheckoutService } from '../shared/services/catalog-checkout.service';

import { CheckoutCart } from './../shared/models/checkout.model';
import { ModalService } from '@shared/ui-modal';
import { BreadcrumbDatasource } from '@shared/ui/breadcrumb';
import { CatalogProduct } from '../catalog-product/catalog-product-models';
import { CatalogProductService } from '../catalog-product/catalog-product.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'shop-catalog-checkout-cart',
  templateUrl: './catalog-checkout-cart.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogCheckoutCartComponent implements OnInit {
  // @ts-ignore
  cart: CheckoutCart;
  // @ts-ignore
  totalSum: number;
  // @ts-ignore
  shipment: number;
  stocks = Array;
  // @ts-ignore
  country: number;
  discountCode = '';
  // @ts-ignore
  discount: number;
  // @ts-ignore
  showDiscount: boolean;
  breadcrumbDataSource: BreadcrumbDatasource = [
    {
      value: '/',
    },
    {
      name: 'Winkelwagen',
    },
  ];

  constructor(
    private catalogCheckoutService: CatalogCheckoutService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private catalogcheckoutInformationService: CatalogCheckoutInformationService,
    private readonly modalService: ModalService,
    private readonly route: ActivatedRoute,
    private readonly catalogProductService: CatalogProductService,
  ) {}

  /**
   * On Initialization.
   *
   * @memberOf CatalogCheckoutCartComponent
   */
  ngOnInit() {
    const productUrlToBeAdded =
      this.route.snapshot.queryParamMap.get('item_id');
    if (productUrlToBeAdded) {
      this.catalogProductService
        .get(productUrlToBeAdded)
        .subscribe((res: CatalogProduct) => {
          this.catalogCheckoutService.addProduct({
            url: res.url,
            image: res.images[0],
            name: res.name,
            sum: 1,
            price: res.price,
            // @ts-ignore
            salePrice: res.salePrice,
            deliveryTime: 1,
            // @ts-ignore
            stock: res.stock,
            brand: res.brand,
          });
          this.country = this.localStorageService.getShipmentCountry();
          this.cart = this.catalogCheckoutService.checkout.cart;
          this.getCheckoutSummary();
        });
    }

    this.country = this.localStorageService.getShipmentCountry();
    this.cart = this.catalogCheckoutService.checkout.cart;
    this.getCheckoutSummary();
  }

  onBreadcrumbNavigate(value: string) {
    this.router.navigateByUrl(value);
  }

  onRemove(index: number) {
    // this.cart.products.splice(index, 1);
    // this.catalogCheckoutService.checkout.cart = this.cart;

    this.modalService
      .confirm(
        {
          title: 'Verwijderen',
          body: 'Weet je zeker dat je het artikel uit je winkelwagen wilt verwijderen?',
          appearance: 'danger',
          buttonCancel: 'Annuleren',
          buttonContinue: 'Verwijderen',
        },
        'sm',
      )
      .then((t) => {
        if (t) {
          this.catalogCheckoutService.removeProduct(index);
          this.getCheckoutSummary();
        }
      });
  }

  /**
   * Change the Sum of a Product.
   *
   * @param {number} index
   * @param {number} stock
   * @param {number} sum
   *
   * @memberOf CatalogCheckoutCartComponent
   */
  onChangeSum(index: number, stock: number, sum: number) {
    if (sum > stock) {
      sum = stock;
    }

    this.catalogCheckoutService.changeSumProduct(index, sum);
    this.getCheckoutSummary();
  }

  async onOrder() {
    if (this.cart.products.length === 0) {
      return;
    }

    const result = await this.catalogcheckoutInformationService
      .check(this.cart.products)
      .toPromise();
    if (result?.length === 0) {
      alert(
        'De producten die u had in winkelwagen zijn niet meer beschikbaar. Onze excuses voor het ongemak.',
      );
      this.catalogCheckoutService.setProducts(result);
      this.getCheckoutSummary();
      return;
    }

    if (result !== undefined && result?.length !== this.cart.products.length) {
      this.catalogCheckoutService.setProducts(result);
      this.getCheckoutSummary();

      // tslint:disable-next-line:max-line-length
      alert(
        'Er zijn diverse producten uitverkocht, deze zijn uit uw winkelwagen weg gehaald. Onze excuses voor het ongemak. De producten die nog wel beschikbaar zijn staan natuurlijk nog in uw winkelwagen.',
      );
    }

    if (
      result !== undefined &&
      result?.reduce((sum, current) => sum + current.sum, 0) !==
        this.cart.products.reduce((sum, current) => sum + current.sum, 0)
    ) {
      this.catalogCheckoutService.setProducts(result);
      this.getCheckoutSummary();

      // tslint:disable-next-line:max-line-length
      alert(
        'Er zijn diverse producten uitverkocht, deze zijn uit uw winkelwagen weg gehaald. Onze excuses voor het ongemak. De producten die nog wel beschikbaar zijn staan natuurlijk nog in uw winkelwagen.',
      );
    }

    this.router.navigateByUrl('/checkout/information');
  }

  onApplyDiscountCode() {
    this.getCheckoutSummary();
  }

  private changeCountry(country: number) {
    if (+country === 0) {
      if (this.totalSum > 100) {
        this.shipment = 0;
      } else {
        this.shipment = 6.95;
      }
    } else {
      if (this.totalSum > 150) {
        this.shipment = 0;
      } else {
        this.shipment = 9.95;
      }
    }
  }

  /**
   * Get Checkout Summary.
   *
   * @private
   *
   * @memberOf CatalogCartComponent
   */
  private getCheckoutSummary() {
    this.totalSum = 0;

    this.cart.products.forEach((value) => {
      this.totalSum += value.price * value.sum;
    });

    if (this.totalSum > 100 && +this.country === 0) {
      this.shipment = 0;
    } else {
      this.changeCountry(this.country);
    }

    this.changeCountry(this.country);

    this.calculateDiscount();
  }

  private calculateDiscount() {
    // Exit function if no discount code is filled in.
    if (this.discountCode === '') {
      return;
    }

    // Check if Discount Code is valid.
    if (
      this.discountCode.toLowerCase() !== 'xmyn01' &&
      this.discountCode.toLowerCase() !== 'xmyn02' &&
      this.discountCode.toLowerCase() !== 'xmyn03'
    ) {
      alert('Geen geldige koringscode');
    } else {
      this.showDiscount = true;

      // Free shipment only in The Netherlands.
      if (this.discountCode.toLowerCase() === 'xmyn01') {
        this.discount = this.shipment;
        this.shipment = 0;
      }

      // 10% discount.
      if (this.discountCode.toLowerCase() === 'xmyn02') {
        this.discount = (this.totalSum / 100) * 10;
        this.totalSum = (this.totalSum / 100) * 90;
      }

      // 20% discount.
      if (this.discountCode.toLowerCase() === 'xmyn03') {
        this.discount = (this.totalSum / 100) * 20;
        this.totalSum = (this.totalSum / 100) * 80;
      }
    }
  }
}

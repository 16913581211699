import { Routes } from '@angular/router';

import { CatalogCategoryComponent } from './catalog-category/catalog-category.component';
import { CatalogCheckoutCartComponent } from './catalog-checkout-cart/catalog-checkout-cart.component';
import { CatalogCheckoutInformationComponent } from './catalog-checkout-information/catalog-checkout-information.component';
import { CatalogProductComponent } from './catalog-product/catalog-product.component';

export const CatalogRoutes: Routes = [
  { path: 'category/:category', component: CatalogCategoryComponent },
  { path: 'brand/:brand', component: CatalogCategoryComponent },
  { path: 'product/:product', component: CatalogProductComponent },
  { path: 'checkout/cart', component: CatalogCheckoutCartComponent },
  {
    path: 'checkout/information',
    component: CatalogCheckoutInformationComponent,
  },
];

<div
  [class.text-white]="
    appearance === 'primary' ||
    appearance === 'secondary' ||
    appearance === 'dark'
  "
  [ngClass]="{
    'bg-primary-500': appearance === 'primary',
    'bg-secondary-600': appearance === 'secondary',
    'bg-gray-100 text-gray-800': appearance === 'light',
    'bg-gray-800': appearance === 'dark',
    'bg-red-100 text-red-800': appearance === 'danger',
    'bg-blue-100 text-blue-800': appearance === 'info',
    'bg-green-100 text-green-800': appearance === 'success',
    'bg-yellow-100 text-yellow-800': appearance === 'warning'
  }"
  class="rounded p-4 text-sm"
>
  <div class="flex">
    <div class="flex-shrink-0">
      @if (icon === true && appearance === 'success') {
        <shared-icon
          [name]="'check-circle'"
          class="text-green-400"
        ></shared-icon>
      }
      @if (icon === true && appearance === 'danger') {
        <shared-icon [name]="'x-circle'" class="text-red-400"></shared-icon>
      }
      @if (icon === true && appearance === 'warning') {
        <shared-icon
          [name]="'exclamation-circle'"
          class="text-yellow-400"
        ></shared-icon>
      }
      @if (icon === true && appearance === 'info') {
        <shared-icon [name]="'info-circle'" class="text-blue-400"></shared-icon>
      }
    </div>
    <div [class.ml-3]="icon === true">
      <div [innerHTML]="content"></div>
      <ng-content></ng-content>
    </div>
    @if (closable) {
      <div class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <button
            [ngClass]="{
              'hover:bg-primary-600 focus:ring-offset-primary-600 focus:ring-primary-800':
                appearance === 'primary',
              'hover:bg-secondary-700 focus:ring-offset-secondary-600 focus:ring-secondary-800':
                appearance === 'secondary',
              'hover:bg-gray-200 focus:ring-offset-gray-100 focus:ring-gray-200':
                appearance === 'light',
              'hover:bg-gray-900 focus:ring-offset-gray-800 focus:ring-white':
                appearance === 'dark',
              'hover:bg-red-200 focus:ring-offset-red-100 focus:ring-red-300':
                appearance === 'danger',
              'hover:bg-blue-200 focus:ring-offset-blue-100 focus:ring-blue-300':
                appearance === 'info',
              'hover:bg-green-200 focus:ring-offset-green-100 focus:ring-green-300':
                appearance === 'success',
              'hover:bg-yellow-200 focus:ring-offset-yellow-100 focus:ring-yellow-300':
                appearance === 'warning'
            }"
            class="inline-flex rounded p-1.5 focus:ring-2 focus:outline-none focus:ring-offset-2"
            type="button"
          >
            @if (closable) {
              <shared-icon
                (mousedown)="onClose($event)"
                [name]="'x-lg'"
                [size]="'sm'"
              ></shared-icon>
            }
          </button>
        </div>
      </div>
    }
  </div>
</div>

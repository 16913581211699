/* eslint-disable */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  Checkout,
  CheckoutCartProduct,
} from './../shared/models/checkout.model';

@Injectable()
export class CatalogCheckoutInformationService {
  constructor(private http: HttpClient) {}

  check(products: CheckoutCartProduct[]) {
    return this.http.post<CheckoutCartProduct[]>('checkout/check', products);
  }

  /**
   * Pay and get back a string for making the actual Payment.
   *
   * @param {Checkout} checkout
   * @returns
   *
   * @memberOf CatalogCheckoutInformationService
   */
  pay(checkout: Checkout) {
    return this.http.post<any>('checkout/pay', checkout);
  }
}

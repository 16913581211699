import { NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { Angulartics2GoogleAnalytics, Angulartics2Module } from 'angulartics2';

import { AlertModule } from '@shared/ui-alert';
import { BadgeModule } from '@shared/ui-badge';
import { ButtonModule } from '@shared/ui-button';
import { CheckboxModule } from '@shared/ui-checkbox';
import { IconComponent } from '@shared/ui-icon';
import { ModalModule } from '@shared/ui-modal';
import { SelectModule } from '@shared/ui-select';
import { ToastModule, ToastPosition } from '@shared/ui-toast';
import { TooltipModule } from '@shared/ui-tooltip';
import { BreadcrumbComponent } from '@shared/ui/breadcrumb';
import { ClickOutsideModule } from '@shared/util-click-outside';

import { AppRoutingModule } from './app-routing.module';
// eslint-disable-next-line import/order
import { AppComponent } from './app.component';

// App Components.
import { CatalogCategoryComponent } from './catalog/catalog-category/catalog-category.component';
// tslint:disable-next-line:max-line-length
import { CatalogCategoryService } from './catalog/catalog-category/catalog-category.service';
import { CatalogCheckoutCartComponent } from './catalog/catalog-checkout-cart/catalog-checkout-cart.component';
import { CatalogCheckoutInformationComponent } from './catalog/catalog-checkout-information/catalog-checkout-information.component';
import { CatalogCheckoutInformationService } from './catalog/catalog-checkout-information/catalog-checkout-information.service';
import {
  CatalogProductComponent,
  CatalogProductNotifyModalComponent,
} from './catalog/catalog-product/catalog-product.component';
import { CatalogProductService } from './catalog/catalog-product/catalog-product.service';
import { CatalogCategorySelectorComponent } from './catalog/shared/components/catalog-category-selector/catalog-category-selector.component';
import { CatalogCheckoutService } from './catalog/shared/services/catalog-checkout.service';
import { CatalogCompareService } from './catalog/shared/services/catalog-compare.service';
import { CatalogLastViewedService } from './catalog/shared/services/catalog-last-viewed.service';
import { CatalogWishlistService } from './catalog/shared/services/catalog-wishlist.service';
import { CatalogCartComponent } from './components/catalog-cart/catalog-cart.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { SearchComponent } from './components/search/search.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

Sentry.init({
  dsn: 'https://314dc7029a93e1b4c843aa8f52dbc76a@o84020.ingest.sentry.io/4506616746278912',
});

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    FooterComponent,
    SearchComponent,
    NewsletterComponent,
    CopyrightComponent,
    CatalogCartComponent,
    CatalogCategoryComponent,
    CatalogCategorySelectorComponent,
    CatalogProductComponent,
    CatalogProductNotifyModalComponent,
    CatalogCheckoutCartComponent,
    CatalogCheckoutInformationComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule.forRoot(),
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastModule.forRoot({
      position: ToastPosition.BottomRight,
    }),
    SharedModule,
    IconComponent,
    ButtonModule,
    BadgeModule,
    SelectModule,
    CheckboxModule,
    AlertModule,
    NgOptimizedImage,
    ClickOutsideModule,
    BreadcrumbComponent,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Angulartics2Module.forRoot([Angulartics2GoogleAnalytics]),
    TooltipModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    CatalogCheckoutService,
    CatalogWishlistService,
    CatalogCompareService,
    CatalogProductService,
    CatalogCategoryService,
    CatalogCheckoutInformationService,
    CatalogLastViewedService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

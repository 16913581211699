import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';

import { Key } from '@shared/util-key';

import { ModalConfigService } from '../../modal-config.service';
import { ModalDismissReason } from '../../models/modal-dismiss-reason.enum';
import { ModalInternalService } from '../../services/modal-internal.service';

@Component({
  selector: 'shared-modal-container',
  template: `
    <div
      [class]="'fixed z-10 inset-0 overflow-y-auto'"
      role="dialog"
      aria-modal="true"
      focusTrap
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-black bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          (sharedClickOutside)="onOutsideClick()"
          class="
          inline-block
          align-bottom
          bg-white
          rounded-lg
          text-left
          overflow-hidden
          shadow-xl
          transform
          transition-all
          sm:my-8 sm:align-middle sm:w-full
        "
          [ngClass]="{
            'sm:max-w-lg': config.size === 'sm',
            'sm:max-w-3xl': config.size === 'base' || config.size === undefined,
            'sm:max-w-7xl': config.size === 'lg'
          }"
        >
          <div class="bg-white dark:bg-gray-800 p-4 sm:p-6">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalContainerComponent implements OnInit, OnDestroy {
  config: ModalConfigService;
  isShown = false;
  level?: number;
  bsModalService?: ModalInternalService;
  private isModalHiding = false;

  constructor(
    options: ModalConfigService,
    protected _element: ElementRef,
    private _renderer: Renderer2,
  ) {
    this.config = Object.assign({}, options);
  }

  ngOnInit(): void {
    this._renderer.addClass(this._element.nativeElement, 'fade');

    this._renderer.setStyle(this._element.nativeElement, 'display', 'block');
    setTimeout(() => {
      this.isShown = true;
      this._renderer.addClass(this._element.nativeElement, 'show');
    }, 0);
    if (document && document.body) {
      if (this.bsModalService && this.bsModalService.getModalsCount() === 1) {
        this.bsModalService.checkScrollbar();
        this.bsModalService.setScrollbar();
      }
      this._renderer.addClass(document.body, 'open');
      this._renderer.setStyle(document.body, 'overflow-y', 'hidden');
    }
    if (this._element.nativeElement) {
      this._element.nativeElement.focus();
    }
  }

  onOutsideClick(): void {
    if (this.config.ignoreBackdropClick === false) {
      this.bsModalService?.setDismissReason(ModalDismissReason.Backdrop);
      this.hide();
    }
  }

  @HostListener('window:popstate')
  onPopState(): void {
    this.bsModalService?.setDismissReason(ModalDismissReason.Back);
    this.hide();
  }

  @HostListener('window:keydown.esc', ['$event'])
  onEsc(event: KeyboardEvent): void {
    if (!this.isShown) {
      return;
    }

    if (event.key === Key.Escape) {
      event.preventDefault();
    }

    if (
      this.config.keyboard &&
      this.level === this.bsModalService?.getModalsCount()
    ) {
      this.bsModalService?.setDismissReason(ModalDismissReason.Esc);
      this.hide();
    }
  }

  ngOnDestroy(): void {
    if (this.isShown) {
      this._hide();
    }
  }

  hide(): void {
    if (this.isModalHiding || !this.isShown) {
      return;
    }
    this._hide();
  }

  private _hide(): void {
    this.isModalHiding = true;
    this._renderer.removeClass(this._element.nativeElement, 'show');
    setTimeout(() => {
      this.isShown = false;
      if (
        document &&
        document.body &&
        this.bsModalService?.getModalsCount() === 1
      ) {
        this._renderer.removeClass(document.body, 'open');
        this._renderer.setStyle(document.body, 'overflow-y', '');
      }
      this.bsModalService?.hide(this.config.id);
      this.isModalHiding = false;
    }, 0);
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional,
  Self,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { noop } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@shared/util-base-component';

@Component({
  selector: 'shared-checkbox',
  templateUrl: './checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent
  extends BaseComponent
  implements ControlValueAccessor
{
  @Input() label?: string;
  @Input() help?: string;
  @Input() placeholder?: string;
  @Input() id?: string;
  @Input() name?: string;
  isDisabled = false;
  formControl = new FormControl('');
  private fnOnTouchedCallback: () => void = noop;
  private fnOnChangeCallback: (value: string) => void = noop;

  constructor(@Self() @Optional() public control: NgControl) {
    super();

    if (this.control) {
      this.control.valueAccessor = this;
    }

    this.formControl.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .subscribe((x: string) => {
        if (x !== this.control.value) {
          this.fnOnChangeCallback(x);
        }
      });
  }

  registerOnChange(fn: (value: string) => void): void {
    this.fnOnChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.fnOnTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: string): void {
    this.formControl.patchValue(value, { emitEvent: false, onlySelf: true });
  }
}

/* eslint-disable */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ENVIRONMENT } from '../environment/environment.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { Observable } from 'rxjs';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(private localStorageService: LocalStorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = null;
    const authReq = req.clone({
      url: ENVIRONMENT.url + 'api/' + req.url,

      setHeaders: {
        AuthToken: token === null ? '' : token,
      },
    });
    return next.handle(authReq);
  }
}
